import React from "react";
import { useLocation } from "react-router-dom";
import Logo from "../images/MedEssist_Logo_Alt_Type.png";

export default function PoweredBy() {
  const location = useLocation();
  // Check if the URL indicates a SCORM course
  const isScormRoute = location.pathname.includes("dexcom");

  // If SCORM , hide the footer
  if (isScormRoute) return null;

  return (
    <div
      style={{
        margin: "2em",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "150px",
        flexDirection: "column",
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <p style={{ fontWeight: "bold" }}>powered by</p>
        <a
          href="https://www.medessist.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Logo} style={{ height: "40px" }} alt="MedEssist Logo" />
        </a>
      </div>
      <small>Copyright ⓒ 2024 MedEssist - All Rights Reserved</small>
      <p style={{ marginTop: "8px", fontSize: "0.8em", color: "#333" }}>
        If you need assistance, please contact us at
        <a
          href="mailto:info@medessist.com"
          style={{
            color: "#333",
            padding: "2px 4px",
          }}
        >
          info@medessist.com
        </a>
      </p>
    </div>
  );
}
