import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  getCurrentUserObject,
  getAllCourses,
  getAccountData,
  getAllRegistrations,
  getProfessionCounts,
  getExternalCourseClickCounts,
  getAllSpecialCourseStats,
  getSectionsNeeded,
  getAllDexcomScormDataInColumns,
  getAllSingleModuleCourseData,
} from "../helpers/source";
import { useAuth } from "../../contexts/AuthContext";
import {
  Card,
  CssBaseline,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BackButton from "../navbars/BackButton";
import DownloadEmailCSV from "./DownloadEmailCSV";

export default function Admin() {
  const [userObject, setUserObject] = useState({});
  const [courses, setCourses] = useState([]);
  const [emails, setEmails] = useState([]);
  const [registrations, setRegistrations] = useState([]);
  const [professionCounts, setProfessionCounts] = useState({});
  const [externalClickCounts, setExternalClickCounts] = useState({});
  const [completedSections, setCompletedSections] = useState([]);
  const [startedSections, setStartedSections] = useState([]);
  const [rybelsusData, setRybelsusData] = useState([]);

  const { currentUser } = useAuth();

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAllSingleModuleCourseData("rybelsus");
        setRybelsusData(data);
      } catch (err) {
        console.error("Error:", err);
      }
    }
    fetchData();
  }, []);

  const downloadRybelsusCSV = () => {
    if (!rybelsusData.length) {
      alert("No data for Rybelsus found.");
      return;
    }

    // Grab all the columns from the first row object
    const headers = Object.keys(rybelsusData[0]);

    // CSV lines
    const rows = [];
    // header row
    rows.push(headers.join(","));

    // data rows
    rybelsusData.forEach((row) => {
      const vals = headers.map((h) => {
        let val = row[h] ?? "";
        // Escape double quotes
        if (typeof val === "string") {
          val = val.replace(/"/g, '""');
        }
        return `"${val}"`;
      });
      rows.push(vals.join(","));
    });

    const csvString = rows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;

    const dateStr = moment().format("YYYY-MM-DD");
    link.setAttribute("download", `rybelsus_data_${dateStr}.csv`);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const [GLPCourseStats, setGLPCourseStats] = useState({
    quizzesCompleted: {},
    videosWatched: {},
    sectionsCompleted: {},
    modulesStarted: {},
    usersCompleted: [],
    usersEnrolled: 0,
    usersViewed: 0,
    enrolledUsersBySource: {},
  });

  const [GLPCourseStatsFr, setGLPCourseStatsFr] = useState({
    quizzesCompleted: {},
    videosWatched: {},
    sectionsCompleted: {},
    modulesStarted: {},
    usersCompleted: [],
    usersEnrolled: 0,
    usersViewed: 0,
    enrolledUsersBySource: {},
  });

  const [basalInsulinStats, setBasalInsulinStats] = useState({
    quizzesCompleted: {},
    videosWatched: {},
    sectionsCompleted: {},
    modulesStarted: {},
    usersCompleted: [],
    usersEnrolled: 0,
    usersViewed: 0,
    enrolledEmails: [],
    sourceCounts: {},
    enrolledUsersBySource: {},
    longAnswerResponses: [],
  });

  const [basalInsulinStatsFr, setBasalInsulinStatsFr] = useState({
    quizzesCompleted: {},
    videosWatched: {},
    sectionsCompleted: {},
    modulesStarted: {},
    usersCompleted: [],
    usersEnrolled: 0,
    usersViewed: 0,
    enrolledEmails: [],
    sourceCounts: {},
    enrolledUsersBySource: {},
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!currentUser) return;

      try {
        const [
          userRes,
          coursesData,
          accountData,
          registrationData,
          counts,
          clickCounts,
          rybelsusStats,
          glpStats,
          glpStatsFr,
          basalInsulinStatsData,
          basalInsulinStatsFrData,
          completedSections,
          modulesStarted,
        ] = await Promise.all([
          getCurrentUserObject(currentUser.uid),
          getAllCourses(),
          getAccountData(),
          getAllRegistrations(),
          getProfessionCounts(),
          getExternalCourseClickCounts(),

          getAllSpecialCourseStats("managing-t2d-using-glp1ra"),
          getAllSpecialCourseStats("managing-t2d-using-glp1ra-fr"),
          getAllSpecialCourseStats("basal_insulin_mastery_t2d"),
          getAllSpecialCourseStats("basal_insulin_mastery_t2d-fr"),
          getSectionsNeeded("completedSections"),
          getSectionsNeeded("modulesStarted"),
        ]);

        setUserObject(userRes);
        setCourses(coursesData);
        setEmails(accountData.map((data) => data.email));
        setRegistrations(registrationData);
        setProfessionCounts(counts);
        setExternalClickCounts(clickCounts);
        setGLPCourseStats(glpStats);
        setGLPCourseStatsFr(glpStatsFr);
        setBasalInsulinStats(basalInsulinStatsData);
        setBasalInsulinStatsFr(basalInsulinStatsFrData);
        setCompletedSections(completedSections);
        setStartedSections(modulesStarted);
      } catch (error) {
        console.error("Error fetching data:", error);
        alert("Error fetching data. Contact Bita");
      }
    };

    fetchData();
  }, [currentUser]);

  const displaySummaryWithCSV = (title, data, filename, courseId, type) => (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          <b>{title}</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {data.length > 0 ? (
          <button
            className="new-button-style primary"
            onClick={() =>
              convertAndDownloadCSV(data, filename, courseId, type)
            }
          >
            Download CSV ({data.length} entries)
          </button>
        ) : (
          <div>No entries available</div>
        )}
      </AccordionDetails>
    </Accordion>
  );

  // Combines user data with completed or started sections data and generates a CSV file for download
  const convertAndDownloadCSV = (data, filename, courseId, type) => {
    if (!data.length) return;

    const sectionsData =
      type === "completed"
        ? getCompletedSectionsForCourse(courseId)
        : getModulesStartedForCourse(courseId);

    const combinedData = data.map((user) => {
      const sections =
        sectionsData.find((entry) => entry.email === user.email)?.sections ||
        [];
      return {
        ...user,
        sections: sections
          .map((s) => s.sectionId)
          .sort()
          .join(", "),
      };
    });

    if (combinedData.length === 0) {
      alert("No data available to download.");
      return;
    }

    const header = Object.keys(combinedData[0]);

    // handle commas by wrapping fields in quotes
    const csvContent = [
      header.map((field) => `"${field}"`).join(","),
      ...combinedData.map((row) =>
        header
          .map((fieldName) => {
            const value = row[fieldName]
              ? row[fieldName].toString().replace(/"/g, '""')
              : "";
            return `"${value}"`;
          })
          .join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${getReadableDate()}_${filename}.csv`);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };

  const handleDownloadDexcomCsv = async () => {
    try {
      const rows = await getAllDexcomScormDataInColumns();
      if (!rows.length) {
        alert("No Dexcom SCORM data found.");
        return;
      }

      // Build CSV lines
      const headers = Object.keys(rows[0]);
      const lines = [];
      // Header row
      lines.push(headers.map((h) => `"${h}"`).join(","));
      // Data rows
      rows.forEach((row) => {
        const line = headers
          .map((hdr) => {
            let val = row[hdr] || "";
            val = val.toString().replace(/"/g, '""'); // escape quotes
            val = val.replace(/\r?\n|\r/g, " "); // remove newlines
            return `"${val}"`;
          })
          .join(",");
        lines.push(line);
      });

      const csvContent = lines.join("\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `DexcomSCORM_${moment().format("YYYYMMDD_HHmmss")}.csv`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (err) {
      console.error("Error generating Dexcom CSV:", err);
      alert("Error generating Dexcom CSV. See console for details.");
    }
  };

  // Function to generate a readable date string
  const getReadableDate = () => {
    const currentDate = new Date();
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    return currentDate.toLocaleDateString(undefined, options);
  };

  // Processes user data for a given source and returns an array of user objects
  const processUsers = (usersBySource) => {
    const translatePracticeType = (category) => {
      const categoriesMap = {
        communityPharmacyCorporate: "Corporate Community Pharmacy",
        communityPharmacyIndependent: "Independent Community Pharmacy",
        healthcareConsulting: "Healthcare Consulting",
        governmentInsurance: "Government Insurance",
        hospitalPharmacy: "Hospital Pharmacy",
        familyHealthTeam: "Family Health Team",
        publicHealth: "Public Health",
        other: "Other",
        community: "Community",
      };

      return categoriesMap[category] || category;
    };

    return Object.entries(usersBySource || {}).flatMap(([source, users]) =>
      users.map(
        ({
          firstName,
          lastName,
          email,
          province,
          registrationDate,
          courseEnrolledDate,
          profession,
          practiceType,
          company,
          longAnswerResponses,
        }) => {
          // issue: registrationDate (ISO) & courseEnrolledDate (MM/DD/YYYY) are in diff formats

          // change ISO registrationDate to MM/DD/YYYY
          const formattedRegistrationDate = registrationDate
            ? moment(registrationDate).format("MM/DD/YYYY")
            : "Date not recorded";

          // make sure courseEnrolledDate remains in MM/DD/YYYY
          const formattedCourseEnrolledDate = courseEnrolledDate
            ? moment(courseEnrolledDate, "MM/DD/YYYY").format("MM/DD/YYYY")
            : "Date not recorded";

          return {
            firstName,
            lastName,
            email,
            province,
            profession,
            registrationDate: formattedRegistrationDate,
            courseEnrolledDate: formattedCourseEnrolledDate,
            source, // where user enrolled from
            longAnswerResponses,
            practiceType: translatePracticeType(practiceType) || "--",
            company: company || "--",
          };
        }
      )
    );
  };

  // Filter and returns completed sections for given course, returns array of objects containing email and sections
  const getCompletedSectionsForCourse = (courseId) => {
    return completedSections
      .filter((entry) => entry.courseId === courseId)
      .map((entry) => ({
        email: entry.email,
        sections: entry.sections.sort((a, b) =>
          a.sectionId.localeCompare(b.sectionId)
        ), // Sort sections by sectionId so that they appear organized in csv (otherwise random inconsistent order)
      }));
  };

  // Filter and returns started modules for given course, returns array of objects containing email and sections
  const getModulesStartedForCourse = (courseId) => {
    return startedSections
      .filter((entry) => entry.courseId === courseId)
      .map((entry) => ({
        email: entry.email,
        sections: entry.sections.sort((a, b) =>
          a.sectionId.localeCompare(b.sectionId)
        ), // Sort sections by sectionId so that they appear organized in csv (otherwise random inconsistent order)
      }));
  };

  const renderFullCourseStats = (courseStats, titlePrefix, courseId) => {
    // Ensure courseStats is not null or undefined
    if (!courseStats) return null;
    return (
      <>
        <Typography variant="h6" gutterBottom>
          Special Course Stats: {titlePrefix}
        </Typography>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <b>Views:</b>
          <div>Total Users Viewed: {courseStats.usersViewed}</div>

          <b>Enrollments:</b>
          <div>Total Enrolled Users: {courseStats.usersEnrolled}</div>

          <b>Sections Completed:</b>
          {Object.entries(courseStats.sectionsCompleted || {}).map(
            ([section, count]) => (
              <div key={section}>
                {section}: {count} users completed
              </div>
            )
          )}
          <b>Sections Started:</b>
          {Object.entries(courseStats?.modulesStarted || {}).map(
            ([section, count]) => (
              <div key={section}>
                {section}: {count} users started this module
              </div>
            )
          )}
          {displaySummaryWithCSV(
            "Users Who Completed the Course",
            courseStats.usersCompleted || [],
            "completed_users",
            courseId
          )}

          {displaySummaryWithCSV(
            "Enrolled Users' with Completed Modules",
            processUsers(courseStats.enrolledUsersBySource || {}),
            "completed_modules",
            courseId,
            "completed"
          )}
          {displaySummaryWithCSV(
            "Enrolled Users' with Started Modules",
            processUsers(courseStats.enrolledUsersBySource || {}),
            "started_modules",
            courseId,
            "started"
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <CssBaseline />
      <Card className="admin-card">
        <BackButton />
        {userObject?.admin ? (
          <>
            <Typography variant="h4" gutterBottom>
              Admin
            </Typography>
            <Typography variant="h6" gutterBottom>
              Course Registrations
            </Typography>
            {courses.map((x) => (
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                key={x.id}
              >
                <div>{x.courseName}</div>
                <b>{registrations.filter((y) => y[x.id]).length}</b>
              </div>
            ))}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "bold",
              }}
            >
              <div>Education Hub Registrations</div>
              <b>{emails.length}</b>
            </div>
            <Divider sx={{ m: 1 }} />
            <div>
              <Typography variant="h6">Profession Breakdown:</Typography>
              {Object.entries(professionCounts).map(([profession, count]) => (
                <div key={profession}>
                  {profession} - {count}
                </div>
              ))}
            </div>
            <Divider sx={{ m: 1 }} />

            <h2>Rybelsus Stats (Full Data Export)</h2>
            <button onClick={downloadRybelsusCSV}>Download Rybelsus CSV</button>

            {/*   the antihistamine course */}
            <Typography variant="h6" gutterBottom>
              External Course Clicks
            </Typography>
            {Object.entries(externalClickCounts).map(([courseId, count]) => {
              const course = courses.find(
                (course) => course.courseId === courseId
              );

              return (
                <div
                  key={courseId}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{course.courseName}</div>
                  <b>{count}</b>
                </div>
              );
            })}

            <Divider sx={{ m: 1 }} />
            <button onClick={handleDownloadDexcomCsv}>
              Download Dexcom CSV (1 row / user+course)
            </button>
            <Divider sx={{ m: 1 }} />
            {/* render full course stats */}
            {courses.some(
              (course) => course.courseId === "managing-t2d-using-glp1ra"
            ) &&
              renderFullCourseStats(
                GLPCourseStats,
                "Managing Type 2 Diabetes Using GLP-1 RAs",
                "managing-t2d-using-glp1ra"
              )}
            {courses.some(
              (course) => course.courseId === "managing-t2d-using-glp1ra-fr"
            ) &&
              renderFullCourseStats(
                GLPCourseStatsFr,
                "Managing Type 2 Diabetes Using GLP-1 RAs (FR)",
                "managing-t2d-using-glp1ra-fr"
              )}
            {courses.some(
              (course) => course.courseId === "basal_insulin_mastery_t2d"
            ) &&
              renderFullCourseStats(
                basalInsulinStats,
                "Basal Insulin Mastery for Type 2 Diabetes",
                "basal_insulin_mastery_t2d"
              )}
            {courses.some(
              (course) => course.courseId === "basal_insulin_mastery_t2d-fr"
            ) &&
              renderFullCourseStats(
                basalInsulinStatsFr,
                "Basal Insulin Mastery for Type 2 Diabetes (FR)",
                "basal_insulin_mastery_t2d-fr"
              )}

            <Divider sx={{ m: 1 }} />
            <DownloadEmailCSV />
          </>
        ) : (
          "You do not have admin access"
        )}
      </Card>
    </>
  );
}
