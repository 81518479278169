import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Modal,
  Box,
  IconButton,
  Rating,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import {
  markSectionAsComplete,
  getCurrentUserObject,
  rateCourse,
} from "../../helpers/source";
import { useTranslation } from "react-i18next";

const SectionDoneMultiCEUCourse = ({
  currentStepObject,
  handlePreviousStep,
  handleNextStep,
  currentUser,
  courseId,
  setCompletedSections,
  completedSections,
  hardCodedCourseContent,
}) => {
  const { t, i18n } = useTranslation();
  const [isCompleting, setIsCompleting] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [rating, setRating] = useState(0);
  const [submittedRating, setSubmittedRating] = useState(false);
  const [thankYouMessage, setThankYouMessage] = useState("");
  const [isLastModule, setIsLastModule] = useState(false);
  const [areAllModulesCompleted, setAreAllModulesCompleted] = useState(false);

  // Effect to mark the section as complete if not already completed
  useEffect(() => {
    const sectionId = currentStepObject?.sectionId;
    const moduleIndex = hardCodedCourseContent.sections.findIndex(
      (module) => module.certificateId === sectionId
    );
    setIsLastModule(moduleIndex === hardCodedCourseContent.sections.length - 1);
    // Check if the section is not already completed
    if (!completedSections?.[sectionId]) {
      markSectionComplete(sectionId);
    } else {
      setIsCompleted(true);
    }

    const fetchRating = async () => {
      if (currentUser) {
        const userObject = await getCurrentUserObject(currentUser?.uid);
        const courses = userObject?.courses || [];

        if (Object.keys(courses).includes(courseId)) {
          const courseRating = courses?.[courseId]?.rating;
          setRating(courseRating || 0);

          if (courseRating) {
            setSubmittedRating(true);
          }
        }
      }
    };

    fetchRating();
    checkIfAllModulesCompleted();
  }, [currentStepObject, completedSections, currentUser, courseId]);

  const markSectionComplete = async (sectionId) => {
    setIsCompleting(true);
    try {
      const currentModule = hardCodedCourseContent.sections.find(
        (module) => module.certificateId === currentStepObject.sectionId
      );

      await markSectionAsComplete({
        userId: currentUser?.uid,
        courseId,
        sectionId,
        credits: currentModule?.credits,
        multipleCreditCourse: hardCodedCourseContent?.multipleCreditCourse,
        moduleName: currentModule?.title,
        accreditationNumber: currentModule.accreditationNumber,
        isThisAModuleThatGetsACredit: true,
      });

      const updatedCompletedSections = {
        ...completedSections,
        [sectionId]: true,
      };

      setCompletedSections(updatedCompletedSections);
      setIsCompleted(true);
      checkIfAllModulesCompleted();
    } finally {
      setIsCompleting(false);
    }
  };

  const checkIfAllModulesCompleted = () => {
    const allModulesCompleted = hardCodedCourseContent.sections.every(
      (module) => completedSections?.[module.certificateId]
    );
    setAreAllModulesCompleted(allModulesCompleted);
  };

  const handleChangeRating = async (event, newValue) => {
    setRating(newValue);

    if (newValue > 0) {
      await rateCourse({
        userId: currentUser.uid,
        courseId: courseId,
        rating: newValue,
      });

      if (submittedRating) {
        setThankYouMessage(t("interactiveCourse.ratingUpdated"));
      } else {
        setThankYouMessage(t("interactiveCourse.ratingThanks"));
      }

      setSubmittedRating(true);
    }
  };

  const currentModule = hardCodedCourseContent.sections.find(
    (module) => module.certificateId === currentStepObject.sectionId
  );

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };

  // Check if current language is English
  const isEnglish = i18n.language === "en";

  return (
    <div
      className="centered-container"
      style={{ textAlign: "center", padding: 20 }}
    >
      <h2
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CheckCircleIcon
          style={{ color: "green", fontSize: 40, margin: "5px" }}
        />
        {t("interactiveCourse.successfullyCompleted")}
      </h2>
      {/*   wording displayed depends on if its the last module and if all modules are
      completed */}
      <p>
        {isCompleting
          ? t("interactiveCourse.finalizingProgress")
          : isCompleted
          ? areAllModulesCompleted
            ? t("interactiveCourse.allModulesCompleted")
            : isLastModule
            ? t("interactiveCourse.lastModuleCompleted")
            : t("interactiveCourse.moduleCompleted")
          : t("interactiveCourse.finishedModule")}{" "}
      </p>

      {isCompleted && <p>{t("interactiveCourse.viewStatement")}</p>}
      {isCompleting && <CircularProgress />}
      <br />
      {/* Rating section */}
      {isCompleted && (
        <div style={{ marginBottom: "20px", textAlign: "center" }}>
          <Typography variant="h6">
            {t("interactiveCourse.rateCourse")}
          </Typography>
          <Rating
            name="course-rating"
            value={rating}
            onChange={handleChangeRating}
          />
          {thankYouMessage && (
            <Typography
              variant="body2"
              style={{ marginTop: "10px", color: "green" }}
            >
              {thankYouMessage}
            </Typography>
          )}
        </div>
      )}

      {/* Conditionally Render Additional Resources  if they are on the last module , and language is English*/}
      {areAllModulesCompleted &&
        courseId === "basal_insulin_mastery_t2d" &&
        isEnglish && ( // english check (we only have english resources)
          <div style={{ marginTop: "20px", textAlign: "left" }}>
            <Typography variant="h6" style={{ marginBottom: "px" }}>
              Additional Resources
            </Typography>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              <li
                style={{
                  marginBottom: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    marginRight: "10px",
                    fontWeight: "bold",
                  }}
                >
                  <a
                    href="https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/resources%2FInsulin%20Icodec%20Handout%20for%20HCPs.pdf?alt=media&token=26a640e4-036a-40f9-b103-c871ac8b51cb"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: "none",
                      color: "#1976d2",
                    }}
                  >
                    Insulin Icodec Handout for HCPs
                  </a>
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  - A handy reference for you.
                </Typography>
              </li>
              <li
                style={{
                  marginBottom: "20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    marginRight: "10px",
                    fontWeight: "bold",
                  }}
                >
                  <a
                    href="https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/resources%2FInsulin%20Icodec%20Handout%20for%20Patients.pdf?alt=media&token=60fd1dfe-76e1-4158-af7b-8da6cf5d1334"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: "none",
                      color: "#1976d2",
                    }}
                  >
                    Insulin Icodec Handout for Patients
                  </a>
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  - Designed to share with your patients!
                </Typography>
              </li>
            </ul>
          </div>
        )}

      <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
        <button
          // Button to go to the previous step
          onClick={handlePreviousStep}
          className="new-button-style secondary"
        >
          {t("interactiveCourse.back")}
        </button>

        {!isCompleted && (
          // Button to start the next module if not completed
          <button onClick={handleNextStep} className="new-button-style primary">
            {t("interactiveCourse.startNextModule")}
          </button>
        )}

        {isCompleted && (
          <button
            // Button to show the survey modal if the module is completed
            className="new-button-style primary"
            onClick={() => setOpenModal(true)}
          >
            {t("interactiveCourse.completeSurvey")}
          </button>
        )}
      </div>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="survey-modal-title"
        aria-describedby="survey-modal-description"
      >
        <Box sx={modalStyle}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2 id="survey-modal-title">
              {t("interactiveCourse.completeSurvey")}
            </h2>
            <IconButton onClick={() => setOpenModal(false)} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
          <iframe
            src={currentModule?.surveyLink}
            width="100%"
            height="600px"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen
            title="Survey"
          ></iframe>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <button
              className="new-button-style primary"
              onClick={() => {
                setOpenModal(false);
                handleNextStep(); // Proceed to next module after survey
              }}
            >
              {isLastModule
                ? t("courseCertificate.close")
                : t("interactiveCourse.startNextModule")}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default SectionDoneMultiCEUCourse;
