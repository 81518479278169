import React, { useEffect, useState } from "react";
import ResourcesSideBar from "./ResourcesSideBar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import DeviceResources from "./DeviceResources";
import SearchResourceList from "./SearchResourceList";
import CopyAndEmailResourcesButtons from "./CopyAndEmailResourcesButtons";
import { resourceIcons } from "../courses/TabContent/TabContent";
import ArticleIcon from "@mui/icons-material/Article";
import { resourcesList } from "./resourceList";
import ResourceListMobile from "./ResourseListMobile";
import MobileCopyAndEmailResourceButton from "./MobileCopyAndEmailResourceButtons";
import "./resources.scss";
import { actionCounter } from "../helpers/source";
import SearchBar from "../courses/SearchBar/SearchBar";
import { useAuth } from "../../contexts/AuthContext";
import { getCurrentUserObject } from "../helpers/source";
import FilterResourcesButtons from "./FilterResourcesButtons";
import { getResourceTypeColour } from "./resourceFunctions";

export default function Resources(props) {
  const { currentUser } = useAuth();

  const [linksToShow, setLinksToShow] = useState(resourcesList()[0]);
  const [searchQuery, setSearchQuery] = useState("");
  const [userProfession, setUserProfession] = useState({});
  const [filtersTurnedOn, setFiltersTurnedOn] = useState([]);
  const [linksWithSearchItem, setLinksWithSearchItem] = useState([]);

  useEffect(() => {
    getCurrentUserObject(currentUser.uid).then((res) => {
      setUserProfession(res.profession);
      setLinksToShow(resourcesList(res.profession)[0]);
    });
  }, [currentUser]);

  const displayListItem = (linkGroup, index) => {
    return (
      <ListItem
        className="resource-list-item"
        sx={{
          flexGrow: "100",
          padding: "0",
          background: getResourceTypeColour(linkGroup?.typeOfResource),
          borderRadius: "10px",
          margin: "3px 0",
        }}
        key={index}
      >
        <ListItemButton
          component="a"
          href={linkGroup.link}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ flexGrow: "100" }}
          onClick={() => {
            if (
              linksToShow?.resourceType === "Devices" &&
              linkGroup?.title.includes("Aerochamber")
            ) {
              actionCounter("trudell", "aerochamberResourceClicked");
            }
            // track num of times a dexcom resource was clicked -- added Feb 14, 2025
            if (linksToShow?.resourceType === "Dexcom") {
              actionCounter("dexcom", "resourceClicks");
            }
          }}
        >
          {resourceIcons[linkGroup.icon] || resourceIcons[linkGroup.type] || (
            <ArticleIcon />
          )}
          <div style={{ margin: "0 10px" }}>{linkGroup.title}</div>
        </ListItemButton>

        <div style={{ display: "flex" }}>
          <CopyAndEmailResourcesButtons
            resourceType={linksToShow?.resourceType}
            linkObj={linkGroup}
          />
          <MobileCopyAndEmailResourceButton
            resourceType={linksToShow?.resourceType}
            linkObj={linkGroup}
          />
        </div>
      </ListItem>
    );
  };

  // arranges arrays in its group
  const displayResources = (list) => {
    let categoryList = [];
    // gets the "grouping" of the link and puts them in category list (ex: patient resources, provider resources)
    list?.forEach((x) => {
      if (x?.group && !categoryList.includes(x.group)) {
        categoryList.push(x?.group);
      }
    });

    // gets all those in that category
    const getThoseInCAtegory = (groupName) => {
      if (groupName) {
        return list.filter((link) => link?.group === groupName);
      }
    };

    return (
      <>
        {/* if they have a category */}
        {categoryList?.length > 0 ? (
          <>
            {categoryList
              .sort((a, b) => a.localeCompare(b))
              .map((groupName, index) => (
                <div key={index}>
                  <h4>{groupName}</h4>
                  <List>
                    {getThoseInCAtegory(groupName)
                      .sort((a, b) => a.title.localeCompare(b.title))
                      .map((linkGroup, index) =>
                        displayListItem(
                          linkGroup,
                          index,
                          getThoseInCAtegory(groupName).length
                        )
                      )}
                  </List>
                </div>
              ))}
          </>
        ) : (
          // if there is no category
          <>
            {list &&
              list
                .sort((a, b) => a.title.localeCompare(b.title))
                .map((linkGroup, index) => displayListItem(linkGroup, index))}
          </>
        )}
      </>
    );
  };

  return (
    <div style={{ display: "flex" }}>
      <div>
        <ResourcesSideBar
          linksToShow={linksToShow}
          setLinksToShow={setLinksToShow}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          userProfession={userProfession}
          setFiltersTurnedOn={setFiltersTurnedOn}
        />
      </div>

      <div style={{ padding: "1rem" }}>
        <>
          <SearchBar
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            placeholderText={"Search all resources"}
          />
          <ResourceListMobile
            linksToShow={linksToShow}
            setLinksToShow={setLinksToShow}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            userProfession={userProfession}
            setFiltersTurnedOn={setFiltersTurnedOn}
          />
          <FilterResourcesButtons
            filtersTurnedOn={filtersTurnedOn}
            setFiltersTurnedOn={setFiltersTurnedOn}
            searchQuery={searchQuery}
            linksWithSearchItem={linksWithSearchItem}
            setLinksWithSearchItem={setLinksWithSearchItem}
            userProfession={userProfession}
            setLinksToShow={setLinksToShow}
          />
          {searchQuery || linksToShow?.resourceType === "search" ? (
            <SearchResourceList
              displayListItem={displayListItem}
              searchQuery={searchQuery}
              userProfession={userProfession}
              filtersTurnedOn={filtersTurnedOn}
              linksWithSearchItem={linksWithSearchItem}
              setLinksWithSearchItem={setLinksWithSearchItem}
            />
          ) : linksToShow?.resourceType === "Devices" ? (
            <div>
              <DeviceResources displayListItem={displayListItem} />
            </div>
          ) : (
            [linksToShow?.linkArrays]?.map((linkGroup) => (
              <>{displayResources(linkGroup)}</>
            ))
          )}
        </>
      </div>
    </div>
  );
}
