/** This file defines a SCORM 1.2 API wrapper on window.API so that SCORM packages
 * can find and interact with it.*


/**
 Helper: parse "cmi.interactions.0.result" -> { index: "0", field: "result" }
 for the raw SCORM data
*/
function parseInteractionKey(key) {
  const parts = key.split(".");
  if (parts.length < 4) return null;
  // parts[2] is the index, parts[3..] is the field
  return {
    index: parts[2],
    field: parts.slice(3).join("."),
  };
}

// Deep merge utility to merge two objects
function deepMerge(target, source) {
  for (let key in source) {
    if (
      source[key] instanceof Object &&
      key in target &&
      target[key] instanceof Object
    ) {
      deepMerge(target[key], source[key]);
    } else {
      target[key] = source[key];
    }
  }
  return target;
}

function computeLastSixScore(scormCache) {
  // 1) Get interactions (each question answered = 1 interaction)
  const interactions = scormCache.interactions || {};

  // 2) Sort descending by index, take the last 6
  const sorted = Object.entries(interactions).sort((a, b) => {
    const aIndex = parseInt(a[0], 10);
    const bIndex = parseInt(b[0], 10);
    return bIndex - aIndex; // newest first
  });
  const lastSix = sorted.slice(0, 6);

  // 3) Count how many were correct
  let correctCount = 0;
  lastSix.forEach(([_, iObj]) => {
    if (iObj.result === "correct") {
      correctCount++;
    }
  });

  // 4) Calculatte final Score out of 6 => percentage
  const finalScore = Math.round((correctCount / 6) * 100);
  return finalScore;
}

function markAsCompletedIfPassed(finalScore) {
  // Build object
  const updateData = {
    courseName: window.courseName,
    userId: window.currentUser?.uid,
    courseId: window.currentCourseId,
    finished: true,
    score: finalScore,
  };

  // If finalScore >= 80 => "passed"
  if (finalScore >= 80) {
    updateData.completed = new Date();
  }

  // update firebase
  if (window.updateScormData) {
    window.updateScormData(updateData);
  }

  // mark completed
  if (finalScore >= 80) {
    window.setReactHasCompleted(true);
  }
}

// ===========================
// 2) DEFINE window.API
// ===========================
if (!window.API) {
  window.API = {
    // Keep track of SCORM data in memory
    scormBuffer: {},
    scormCache: {},
    interactionCounter: 0,

    // ---------------------------------------------------
    // LMSInitialize
    // ---------------------------------------------------
    LMSInitialize: function () {
      console.log("LMSInitialize called");

      // Initialize interactionCounter from existing data
      if (window.API.scormCache.interactions) {
        window.API.interactionCounter = Object.keys(
          window.API.scormCache.interactions
        ).length;
      } else {
        window.API.interactionCounter = 0;
      }

      return "true";
    },

    // ---------------------------------------------------
    // LMSFinish
    // ---------------------------------------------------
    // --------------------------------------------------------------------
    // Called when the SCORM course finishes (user exits the course).
    // We'll mark the course as 'finished' in Firebase
    // --------------------------------------------------------------------
    LMSFinish: function () {
      console.log("LMSFinish called");
      if (
        window.currentUser &&
        window.currentCourseId &&
        window.updateScormData
      ) {
        // Commit any buffered data
        if (Object.keys(window.API.scormBuffer).length > 0) {
          window.updateScormData({
            userId: window.currentUser.uid,
            courseId: window.currentCourseId,
            scormData: window.API.scormBuffer,
          });
          window.API.scormBuffer = {};
        }

        // 1) Compute final score from last 6 interactions
        const finalScore = computeLastSixScore(window.API.scormCache);

        if (finalScore >= 80) {
          window.redirectToProfile();
        }
      }

      return "true";
    },

    // ---------------------------------------------------
    // LMSGetValue
    // ---------------------------------------------------
    LMSGetValue: function (key) {
      //  console.log("LMSGetValue called with:", key);
      if (!window.API.scormCache) {
        return "";
      }

      if (key === "cmi.suspend_data") {
        return window.API.scormCache.suspend_data || "";
      } else if (key === "cmi.core.lesson_location") {
        console.log(window.API.scormCache.lesson_location);
        return window.API.scormCache.lesson_location || "";
      } else if (key === "cmi.core.lesson_status") {
        return window.API.scormCache.lesson_status || "not attempted";
      } else if (key === "cmi.core.score.raw") {
        return window.API.scormCache.score != null
          ? String(window.API.scormCache.score)
          : "";
      }
      // cmi.interactions
      else if (key.startsWith("cmi.interactions.")) {
        const parts = key.split(".");
        if (parts[2] === "_count") {
          if (!window.API.scormCache.interactions) return "0";
          return String(Object.keys(window.API.scormCache.interactions).length);
        } else {
          // e.g. cmi.interactions.0.result
          const parsed = parseInteractionKey(key);
          if (!parsed) return "";
          const { index, field } = parsed;

          if (
            window.API.scormCache.interactions &&
            window.API.scormCache.interactions[index] &&
            window.API.scormCache.interactions[index][field]
          ) {
            return String(window.API.scormCache.interactions[index][field]);
          }
          return "";
        }
      }

      // fallback
      return "";
    },

    // ---------------------------------------------------
    // LMSSetValue
    // ---------------------------------------------------
    LMSSetValue: function (key, val) {
      //  console.log("LMSSetValue called:", key, val);

      // Only buffer updates if we have the user/course info and updateScormData available
      if (
        window.currentUser &&
        window.currentCourseId &&
        window.updateScormData
      ) {
        const scormData = {};

        if (key.startsWith("cmi.interactions.")) {
          const parsed = parseInteractionKey(key);
          if (parsed) {
            let { index, field } = parsed;

            // If setting the 'id' field, check if it's a new interaction
            if (field === "id") {
              // Check if this 'id' already exists
              const existingIds = window.API.scormCache.interactions
                ? Object.values(window.API.scormCache.interactions).map(
                    (i) => i.id
                  )
                : [];

              if (!existingIds.includes(val)) {
                // Assign a new index
                index = String(window.API.interactionCounter);
                window.API.interactionCounter += 1;
              }
            }

            // Ensure interactions map exists
            if (!scormData.interactions) {
              scormData.interactions = {};
            }
            if (!scormData.interactions[index]) {
              scormData.interactions[index] = {};
            }
            scormData.interactions[index][field] = val;
          }
        }
        // Other SCORM keys
        else if (key === "cmi.core.lesson_status") {
          scormData.lesson_status = val;
        } else if (key === "cmi.core.lesson_location") {
          scormData.lesson_location = val;
          console.log("Detected lesson location:", val);

          // detect "passed" page
          if (
            val.includes(
              // english pass course page
              "index.html#/lessons/JHic7SIBK89rYEVDMLb3yDOJYYaLJ6m-"
            ) ||
            // french pass course page
            val.includes("index.html#/lessons/lrhlw7zIQFRS7h8lrsnlTJz5U6SE8e9o")
          ) {
            console.log("Detected pass location. Checking final score now...");
            const finalScore = computeLastSixScore(window.API.scormCache);
            if (finalScore >= 80) {
              markAsCompletedIfPassed(finalScore);
            }
          }
        } else if (key === "cmi.suspend_data") {
          scormData.suspend_data = val;
        } else if (key === "cmi.core.exit") {
          scormData.exit_status = val;
        } else if (key === "cmi.core.total_time") {
          scormData.total_time = val;
        } else if (key === "cmi.core.lesson_mode") {
          scormData.lesson_mode = val;
        } else if (key === "cmi.core.credit") {
          scormData.credit = val;
        } else if (key === "cmi.core.student_id") {
          scormData.student_id = val;
        } else if (key === "cmi.core.student_name") {
          scormData.student_name = val;
        } else if (key === "cmi.core.score.raw") {
          scormData.score = parseInt(val, 10);
        } else if (key === "cmi.core.score.max") {
          scormData.score_max = parseInt(val, 10);
        } else if (key === "cmi.core.score.min") {
          scormData.score_min = parseInt(val, 10);
        } else if (key === "cmi.core.entry") {
          scormData.entry_mode = val;
        }

        // Merge into scormBuffer + scormCache
        if (Object.keys(scormData).length > 0) {
          deepMerge(window.API.scormBuffer, scormData);
          deepMerge(window.API.scormCache, scormData);
        }
      }

      return "true";
    },

    // ---------------------------------------------------
    // LMSCommit
    // ---------------------------------------------------
    LMSCommit: function () {
      console.log("LMSCommit called");
      // If we have any buffered data, send it now
      if (Object.keys(window.API.scormBuffer).length > 0) {
        if (
          window.currentUser &&
          window.currentCourseId &&
          window.updateScormData
        ) {
          const dataToSave = window.API.scormBuffer;
          window.API.scormBuffer = {}; // clear buffer

          // Add interactionCounter
          const dataWithCounter = {
            ...dataToSave,
            interactionCounter: window.API.interactionCounter,
          };

          window.updateScormData({
            userId: window.currentUser.uid,
            courseId: window.currentCourseId,
            scormData: dataWithCounter,
          });
        }
      }
      return "true";
    },

    // ---------------------------------------------------
    // SCORM error handling stubs
    // ---------------------------------------------------
    LMSGetLastError: function () {
      return "0";
    },
    LMSGetErrorString: function (errCode) {
      return "No error";
    },
    LMSGetDiagnostic: function (errCode) {
      return "No error";
    },
  };
}

// Optionally export the API if you want to import it in code
export default window.API;
