import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  IconButton,
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import { useAuth } from "../../contexts/AuthContext";
import "./navbar.scss";
import educationHubLogo from "../../images/MedEssistHub_Light_2.png";
import { getCurrentUserObject } from "../helpers/source";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import StarsIcon from "@mui/icons-material/Stars";
import LanguageIcon from "@mui/icons-material/Language";

function TopNavBar() {
  const { currentUser } = useAuth();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userObject, setUserObject] = useState({});

  const isScormRoute = location.pathname.includes("dexcom");

  useEffect(() => {
    // Each time the route changes, reset isMenuOpen to CLOSED if it's SCORM
    if (isScormRoute) {
      setIsMenuOpen(false);
    } else {
      // If not scorm, open for large screens, closed for small
      if (window.innerWidth > 1200) {
        setIsMenuOpen(true);
      } else {
        setIsMenuOpen(false);
      }
    }
  }, [location.pathname, isScormRoute]);

  // for switching languages
  const { i18n, t } = useTranslation();
  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (currentUser) {
      getCurrentUserObject(currentUser.uid).then((res) => {
        setUserObject(res);
      });
    }
  }, [currentUser]);

  const menuItems = [
    {
      text: currentUser ? t("nav.myCourses") : "Preview Courses",
      icon: <StarsIcon />,
      link: "/courses",
    },
    currentUser && {
      text: "Resources",
      icon: <LocalLibraryIcon />,
      link: "/resources",
    },
    currentUser && {
      text: t("nav.share"),
      icon: <Diversity1Icon />,
      link: "/referral",
    },
    userObject.profession === "Pharmacist" && {
      text: t("nav.blog"),
      icon: <AutoStoriesIcon />,
      link: "/blog",
    },
    currentUser && {
      text: t("nav.aboutUs"),
      icon: <ContactSupportIcon />,
      link: "/about",
    },
    !currentUser && {
      text: t("nav.aboutUs"),
      icon: <ContactSupportIcon />,
      link: "/about",
    },
  ];

  const menuItemsBottom = [
    currentUser && {
      text: t("nav.myAccount"),
      icon: <AccountCircleIcon />,
      link: "/profile",
    },
    currentUser &&
      userObject.admin && {
        text: t("nav.admin"),
        icon: <AdminPanelSettingsIcon />,
        link: "/admin",
      },
  ];

  // Decide if we should show "temporary drawer" or "permanent drawer"
  // If scorm => temporary
  // If screen <= 1200 => temporary
  // otherwise => permanent
  const shouldUseTemporaryDrawer = isScormRoute || window.innerWidth <= 1200;

  return (
    <>
      {shouldUseTemporaryDrawer && (
        <>
          <IconButton color="inherit" aria-label="menu" onClick={toggleMenu}>
            <MenuIcon
              sx={{
                fontSize: 32,
                color: "#229D98",
              }}
            />
          </IconButton>

          <Drawer
            variant="temporary"
            anchor="left"
            open={isMenuOpen}
            onClose={toggleMenu}
            PaperProps={{
              sx: {
                backgroundColor: "#229D98",
                color: "white",
                border: "none",
                borderRadius: "0 0 20px 0",
                height: "90%",
              },
            }}
            sx={{
              width: "300px",
            }}
          >
            <List
              sx={{
                width: 300,
                "@media (max-width: 600px)": { width: 180 },
                background: "#229D98",
              }}
            >
              <Link to="/">
                <div className="logo-container">
                  <img
                    src={educationHubLogo}
                    alt="Education Hub Logo"
                    className={`logo logged-in-logo`}
                  />
                </div>
              </Link>

              {menuItems
                .filter((item) => item) // Filter out undefined items
                .map((item) => (
                  <ListItemButton
                    selected={window.location.pathname === item?.link}
                    component={Link}
                    to={item?.link}
                    key={item?.text}
                    onClick={() => {
                      toggleMenu();
                    }}
                    sx={{
                      fontSize: "1.5rem",
                      "&.Mui-selected": {
                        backgroundColor: "#5cbab7",
                      },
                      "&.Mui-focusVisible": {
                        backgroundColor: "#2e8b57",
                      },
                      ":hover": {
                        backgroundColor: "#5cbab7",
                      },
                    }}
                  >
                    <ListItemIcon>{item?.icon}</ListItemIcon>
                    <ListItemText primary={item?.text} />
                    {item?.action && item?.action()}
                  </ListItemButton>
                ))}
            </List>

            <div style={{ marginTop: "auto" }}>
              {menuItemsBottom
                .filter((item) => item) // Filter out undefined items
                .map((item) => (
                  <ListItemButton
                    selected={window.location.pathname === item?.link}
                    component={Link}
                    to={item?.link}
                    key={item?.text}
                    onClick={() => {
                      toggleMenu();
                    }}
                    sx={{
                      fontSize: "1.5rem",
                      "&.Mui-selected": {
                        backgroundColor: "#5cbab7",
                      },
                      "&.Mui-focusVisible": {
                        backgroundColor: "#2e8b57",
                      },
                      ":hover": {
                        backgroundColor: "#5cbab7",
                      },
                    }}
                  >
                    <ListItemIcon>{item?.icon}</ListItemIcon>
                    <ListItemText primary={item?.text} />
                    {item?.action && item?.action()}
                  </ListItemButton>
                ))}
              {/* Language switcher */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "center",
                  paddingBottom: "2em",
                }}
              >
                <LanguageIcon />
                <FormControl variant="standard" sx={{ m: 1, minWidth: 80 }}>
                  <Select
                    labelId="language-select-label"
                    id="language-select"
                    value={i18n.language}
                    onChange={handleLanguageChange}
                    style={{ color: "white" }}
                  >
                    <MenuItem value="en"> EN</MenuItem>
                    <MenuItem value="fr">FR</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </Drawer>
        </>
      )}

      {!shouldUseTemporaryDrawer && (
        <>
          {/* rendering a drawer component with a list of menu items */}
          <Drawer
            variant="permanent"
            anchor="left"
            open
            PaperProps={{
              sx: {
                backgroundColor: "#229D98",
                color: "white",
                border: "none",
                borderRadius: "0 0 20px 0",
                height: "90%",
              },
            }}
            sx={{
              width: "300px",
            }}
          >
            <List
              sx={{
                width: 300,
                "@media (max-width: 600px)": { width: 180 },
                background: "#229D98",
              }}
            >
              <Link to="/">
                <div className="logo-container">
                  <img
                    src={educationHubLogo}
                    alt="Education Hub Logo"
                    className={`logo logged-in-logo`}
                  />
                </div>
              </Link>

              {menuItems
                .filter((item) => item) // Filter out undefined items
                .map((item) => (
                  <ListItemButton
                    selected={window.location.pathname === item?.link}
                    component={Link}
                    to={item?.link}
                    key={item?.text}
                    onClick={() => {
                      toggleMenu();
                    }}
                    sx={{
                      fontSize: "1.5rem",
                      "&.Mui-selected": {
                        backgroundColor: "#5cbab7",
                      },
                      "&.Mui-focusVisible": {
                        backgroundColor: "#2e8b57",
                      },
                      ":hover": {
                        backgroundColor: "#5cbab7",
                      },
                    }}
                  >
                    <ListItemIcon>{item?.icon}</ListItemIcon>
                    <ListItemText primary={item?.text} />
                    {item?.action && item?.action()}
                  </ListItemButton>
                ))}
            </List>

            <div style={{ marginTop: "auto" }}>
              {menuItemsBottom
                .filter((item) => item) // Filter out undefined items
                .map((item) => (
                  <ListItemButton
                    selected={window.location.pathname === item?.link}
                    component={Link}
                    to={item?.link}
                    key={item?.text}
                    onClick={() => {
                      toggleMenu();
                    }}
                    sx={{
                      fontSize: "1.5rem",
                      "&.Mui-selected": {
                        backgroundColor: "#5cbab7",
                      },
                      "&.Mui-focusVisible": {
                        backgroundColor: "#2e8b57",
                      },
                      ":hover": {
                        backgroundColor: "#5cbab7",
                      },
                    }}
                  >
                    <ListItemIcon>{item?.icon}</ListItemIcon>
                    <ListItemText primary={item?.text} />
                    {item?.action && item?.action()}
                  </ListItemButton>
                ))}
              {/* Language switcher */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "center",
                  paddingBottom: "2em",
                }}
              >
                <LanguageIcon />
                <FormControl variant="standard" sx={{ m: 1, minWidth: 80 }}>
                  <Select
                    labelId="language-select-label"
                    id="language-select"
                    value={i18n.language}
                    onChange={handleLanguageChange}
                    style={{ color: "white" }}
                  >
                    <MenuItem value="en"> EN</MenuItem>
                    <MenuItem value="fr">FR</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </Drawer>
        </>
      )}
    </>
  );
}

export default TopNavBar;
