import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Divider,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useLocation } from "react-router-dom";
import ScormCoursePage from "./ScormCoursePage";
import useCourseEnrollment from "../../components/courses/useCourseEnrollment";
import { useTranslation } from "react-i18next";
import { useUser } from "../../contexts/UserProvider";
import { Helmet } from "react-helmet";

// Acts as Title Page
function ScormCourseWrapper({ courseData, currentUser }) {
  const location = useLocation();
  const { userObject } = useUser();
  const { t, i18n } = useTranslation();

  // Grab the source param from URL
  // e.g. /courses/dexcom_en?source=google
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get("source") || "";

  // State to toggle between the "intro card" and the actual SCORM content
  const [showIntroCard, setShowIntroCard] = useState(true);

  // enrollment hook
  const {
    enrolled,
    enrollmentLoading,
    handleCourseEnrollment,
    handleSignInToEnroll,
  } = useCourseEnrollment(courseData);

  // for google tracking, know its dexcom scorm course if it starts with dexcom (dexcom_en or dexcom_fr)
  const isDexcomCourse = location.pathname.includes("/courses/dexcom");

  // Handle enrollment
  const onEnrollClick = async () => {
    if (!currentUser) {
      // If not logged in, pass the source param to sign-in redirect
      handleSignInToEnroll(source);
      return;
    }

    // Pass the source into enrollment logic
    await handleCourseEnrollment(source);
    setShowIntroCard(false);
  };

  if (enrollmentLoading) {
    return (
      <div style={{ textAlign: "center", marginTop: 50 }}>
        <CircularProgress />
      </div>
    );
  }

  // If the user is enrolled but we still want an intro screen,
  // show a card with "Continue to Course" + Learning Objectives
  if (showIntroCard) {
    return (
      <>
        {isDexcomCourse && (
          <>
            {/* 	Google Analyics Tracking */}
            <Helmet>
              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=AW-11021658284"
              ></script>
              <script>
                {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'AW-11021658284');`}
              </script>
              <script>
                {`
      function gtag_report_conversion(url) {
        var callback = function () {
          if (typeof(url) != 'undefined') {
            window.location = url;
          }
        };
        gtag('event', 'conversion', {
            'send_to': 'AW-11021658284/fT9_CPqDya8ZEKzRxIcp',
            'event_callback': callback
        });
        return false;
      }  
    `}
              </script>
              <script>
                {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-P4BW2SS');`}
              </script>
            </Helmet>
            <noscript>
              <iframe
                src="https://www.googletagmanager.com/ns.html?id=GTM-XXXX"
                height="0"
                width="0"
                style={{ display: "none", visibility: "hidden" }}
              ></iframe>
            </noscript>
          </>
        )}
        <Card
          variant="outlined"
          sx={{
            maxWidth: 900,
            margin: "auto",
            mt: 5,
            p: 3,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: 400,
          }}
        >
          <CardContent>
            {/* Title & Description */}
            <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
              {courseData?.courseName}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {courseData?.description}
            </Typography>

            {/* Watch Time */}
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", fontStyle: "italic", mt: 2 }}
            >
              Watch time: {courseData?.watchTime}
            </Typography>
            <Divider variant="middle" sx={{ my: 2 }} />

            {/* Collaboration Statement*/}
            {courseData?.collabStatement && (
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/educationHub%2FSCORM%2Fdexcom%20logo.png?alt=media&token=c0952211-7206-4d4d-b4fa-b6d9f7824df1"
                  style={{ marginRight: "5px", height: "4rem" }}
                  loading="lazy"
                  alt="Dexcom logo"
                />
                <div>
                  <Typography variant="body1" component="p" gutterBottom>
                    {courseData?.collabStatement}
                  </Typography>
                </div>
              </Box>
            )}

            {/* Learning Objectives */}
            {courseData?.learningObjectives &&
              courseData.learningObjectives.length > 0 && (
                <>
                  <Divider sx={{ my: 3 }} />
                  <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                    {t("interactiveCourse.learningObjectives")}:
                  </Typography>
                  <List>
                    {courseData.learningObjectives.map((obj, idx) => (
                      <ListItem key={idx} sx={{ pl: 0 }}>
                        <ListItemIcon>
                          <CheckCircleIcon color="success" />
                        </ListItemIcon>
                        <ListItemText primary={obj} />
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
          </CardContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 3,
            }}
          >
            {/* Check if the user is logged in */}
            {currentUser ? (
              // If the user is enrolled, show the "Continue to Course" button
              enrolled ? (
                <button
                  className="new-button-style primary teal-hover take-quiz-button"
                  style={{ marginTop: "1rem", padding: "10px 20px" }}
                  onClick={() => setShowIntroCard(false)}
                >
                  {t("interactiveCourse.continueToCourse")}
                </button>
              ) : (
                // If the user is NOT enrolled, show the "Enroll" button
                <button
                  className="new-button-style primary teal-hover take-quiz-button"
                  style={{ marginTop: "1.5rem", padding: "10px 20px" }}
                  onClick={onEnrollClick}
                >
                  {t("interactiveCourse.enrollNow")}
                </button>
              )
            ) : (
              // If the user is NOT logged in, show the "Sign in to Enroll" button
              <button
                className="new-button-style primary teal-hover take-quiz-button"
                style={{ marginTop: "1rem", padding: "10px 20px" }}
                onClick={() => handleSignInToEnroll(source)}
              >
                {t("interactiveCourse.signInToEnroll")}
              </button>
            )}
          </Box>
        </Card>
      </>
    );
  }

  // Once “intro card” is dismissed or the user is already enrolled, show the SCORM iFrame
  return (
    <ScormCoursePage
      courseData={courseData}
      currentUser={currentUser}
      userObject={userObject}
    />
  );
}

export default ScormCourseWrapper;
