import React, { useEffect } from "react";
import { actionCounter } from "../helpers/source";

const DexcomRedirect = () => {
  useEffect(() => {
    // after tracking the qr code scan from dexcom copay storefront poster, redirect to actual resource they are looking for -- added Feb 14, 2025
    const handleRedirect = async () => {
      await actionCounter("dexcom", "qrCodeScans");
      return (window.location.href = `https://s3.us-west-2.amazonaws.com/dexcommisc/Canada+HCP/MAT-0568+CA+G7+Patient+Benefit+Program+Certificate+v3+standard.pdf`);
    };
    handleRedirect();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: "24px",
      }}
    >
      Redirecting... please wait.
    </div>
  );
};

export default DexcomRedirect;
