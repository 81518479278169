import { Stepper, Step, StepLabel, StepButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ProgressPanel({
  sections,
  setCurrentSection,
  setIsSectionIntro,
  setActiveStep,
  activeStep,
  completedSections,
  currentUser,
}) {
  const { t } = useTranslation();

  const handleStep = (index, status) => {
    if (
      status !== "coming soon" &&
      // Check for 'available-internally' status and only @medessist users can see it
      (status !== "available-internally" ||
        (currentUser && currentUser.email.includes("@medessist.com")))
    ) {
      setCurrentSection(index);
      setIsSectionIntro(true);
      setActiveStep(index);
    }
  };

  // Only show Stepper if there are multiple sections
  if (sections.length <= 1) {
    return null; // Hide the progress bar for single-module courses
  }

  return (
    <Stepper
      alternativeLabel
      nonLinear
      activeStep={activeStep}
      style={{ padding: "10px 0", background: "none", marginBottom: "20px" }}
    >
      {sections.map((section, index) => {
        const isComingSoon =
          section.status === "coming soon" ||
          (section.status === "available-internally" &&
            (!currentUser || !currentUser.email.includes("@medessist.com")));

        return (
          <Step
            key={section.id}
            completed={completedSections?.[section.id] || false}
          >
            <Tooltip
              title={
                isComingSoon ? t("interactiveCourse.moduleAvailableSoon") : ""
              }
              placement="bottom"
              arrow
            >
              <StepButton
                // disable button if module is coming soon or non @medessisst users for 'available-internally' modules
                onClick={() => handleStep(index, section.status)}
                disabled={isComingSoon}
                style={{ padding: "10px" }}
              >
                <StepLabel>
                  <div style={{ textAlign: "center" }}>
                    <strong>
                      {section?.progressBarTitle ?? section?.title}
                    </strong>
                    <br />
                    {/* Show "Coming soon" instead of the regular description if the status is not available */}
                    {isComingSoon
                      ? t("interactiveCourse.comingSoon")
                      : section.description}
                  </div>
                </StepLabel>
              </StepButton>
            </Tooltip>
          </Step>
        );
      })}
    </Stepper>
  );
}
