import React from "react";
import { Box, Typography } from "@mui/material";
import "./certificate.scss";
import logo from "../../images/LogoWithOutline.png";
import instructorData from "../courses/TabContent/instructorData";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
function CourseCertificate({
  name,
  course,
  review,
  licenseNumber,
  certificateRef,
}) {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.value);
  const { courses } = user;
  const coursesArray = Object.values(courses);

  //filter for courses that were completed
  const completedCourses = coursesArray.filter(
    (course) => course?.completed && course?.courseId !== "tutorial"
  );

  const metaDataOfCompletedCourse = completedCourses.find(
    (queriedcourse) => queriedcourse?.courseId === course?.courseId
  );

  // parse the completed timestamp as a Date object
  const completedDate = new Date(
    review ? Date.now() : metaDataOfCompletedCourse?.completed?.seconds * 1000
  );

  // format the completed date as a string with just the date using toLocaleDateString
  const completedDateStr = completedDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  // Calculate the expiration date, one year from the completion date
  const expirationDate = new Date(completedDate);
  expirationDate.setFullYear(expirationDate.getFullYear() + 1);

  // Check if the course ID matches the specific English course
  if (
    course.courseName ===
    "Practical Points for Pharmacists Series: Managing Type 2 Diabetes Using GLP-1 RAs"
  ) {
    return (
      <Box
        className="certificate-outer-container"
        style={{ height: "720px" }}
        ref={certificateRef}
      >
        <img
          src="https://live.staticflickr.com/65535/53453876866_e5d1bbd7a8_o.png"
          alt="logo"
          style={{ display: "block", width: "100%", height: "100%" }}
        />
        <Box className="inner-container" id="certificate">
          {/* Certificate Title */}
          <Typography
            className="name"
            style={{
              marginTop: "3em",
              textTransform: "uppercase",
              color: "#EEB741",
            }}
          >
            STATEMENT OF PARTICIPATION
          </Typography>
          {/* Certificate Holder */}
          <Typography variant="h3" className="subtitle">
            This is to acknowledge that
          </Typography>
          <Typography variant="h1" className="name user-name">
            {name}
          </Typography>
          <Typography variant="h6" className="license-number">
            License number: {licenseNumber || ""}
          </Typography>
          {/* Course Completion Information */}
          <Typography variant="h3" className="subtitle">
            {t("courseCertificate.hasCompleted")}
          </Typography>
          <Typography variant="h2" className="course">
            {course.courseName}
          </Typography>
          {/* Completion Date */}
          <Typography
            variant="h4"
            className="date"
            style={{
              textTransform: "uppercase",
              fontWeight: "bold",
              color: "#ababab",
            }}
          >
            {t("courseCertificate.dated")} {completedDateStr}
          </Typography>
          <Box className="medessist-container">
            <img src={logo} alt="MedEssist Logo" className="medessist-logo" />
            <Typography
              variant="h6"
              className="medessist"
              style={{ fontSize: "16px" }}
            >
              {t("courseCertificate.poweredBy")}{" "}
              <a
                href="http://www.hub.medessist.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                www.hub.medessist.com
              </a>
            </Typography>
          </Box>

          <div style={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                margin: "25px 25px 5px 100px",
              }}
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/educationHub%2FCCCEP%2FCCCEP%20logo%20ACCREDITED%20colour.jpg?alt=media&token=bed29524-0990-4042-b5bb-1bf0bb03c489"
                style={{ marginRight: "5px", height: "4rem" }}
                alt="cccep logo"
              />
              <div>
                <Typography variant="body2" component="p" gutterBottom>
                  CCCEP No. 1751-2024-3752-I-P <br />
                  {/*                   Activity Accredited {completedDateStr} <br />
                  Activity Expires {expirationDateStr} <br /> */}
                  Accreditation for 1.5 CEUs <br />
                  Accreditation Type: New Learning Activity
                </Typography>
              </div>
            </Box>
          </div>
        </Box>
      </Box>
    );
  }

  if (
    course.courseName ===
    "Série Points pratiques pour les pharmaciens : Gestion du diabète de type 2 à l'aide des agonistes du récepteur du GLP-1"
  ) {
    return (
      <Box
        className="certificate-outer-container"
        style={{ height: "740px" }}
        ref={certificateRef}
      >
        <img
          src="https://live.staticflickr.com/65535/53453876866_e5d1bbd7a8_o.png"
          alt="logo"
          style={{ display: "block", width: "100%", height: "100%" }}
        />
        <Box className="inner-container" id="certificate">
          {/* Certificate Title */}
          <Typography
            className="name"
            style={{
              marginTop: "3em",
              textTransform: "uppercase",
              color: "#EEB741",
            }}
          >
            DÉCLARATION DE PARTICIPATION
          </Typography>
          {/* Certificate Holder */}
          <Typography variant="h4" className="subtitle">
            Ceci est pour reconnaître que
          </Typography>
          <Typography variant="h2" className="name user-name">
            {name}
          </Typography>
          <Typography variant="h6" className="license-number">
            Numéro de membre : {licenseNumber || ""}
          </Typography>
          {/* Course Completion Information */}
          <Typography variant="h5" className="subtitle">
            {t("courseCertificate.hasCompleted")}
          </Typography>
          <Typography
            variant="h4"
            className="course"
            style={{
              paddingBottom: "5px",
              fontSize: "18px",
              textAlign: "center",
              wordWrap: "break-word",
              width: "80%",
            }}
          >
            {course.courseName}
          </Typography>
          {/* Completion Date */}
          <Typography
            variant="h4"
            className="date"
            style={{
              textTransform: "uppercase",
              fontWeight: "bold",
              color: "#ababab",
            }}
          >
            {t("courseCertificate.dated")} {completedDateStr}
          </Typography>
          <Box className="medessist-container">
            <img src={logo} alt="MedEssist Logo" className="medessist-logo" />
            <Typography
              variant="h6"
              className="medessist"
              style={{ fontSize: "14px" }}
            >
              {t("courseCertificate.poweredBy")}{" "}
              <a
                href="http://www.hub.medessist.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                www.hub.medessist.com
              </a>
            </Typography>
          </Box>

          <div style={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                margin: "25px 25px 5px 100px",
              }}
            >
              <div>
                <Typography variant="body2" component="p" gutterBottom>
                  Cette activité de formation continue est accréditée par
                  l’Ordre des pharmaciens du Québec <br />
                  {/*         Activité accréditée {completedDateStr} <br />
                  Activité expire {expirationDateStr} <br /> */}
                  Accréditation pour 1 UFC <br />
                  Type d'accréditation: Nouvelle activité d'apprentissage
                  <br /> No d'accréditation : 13880
                </Typography>
              </div>
            </Box>
          </div>
        </Box>
      </Box>
    );
  }

  return (
    <Box className="certificate-outer-container" ref={certificateRef}>
      <img
        src="https://live.staticflickr.com/65535/53453876866_e5d1bbd7a8_o.png"
        alt=""
        style={{ display: "block", width: "100%", height: "100%" }}
      />
      <Box className="inner-container" id="certificate">
        {/* Certificate Title */}
        <Typography
          className="name"
          style={{
            marginTop: "3em",
            textTransform: "uppercase",
            color: "#EEB741",
          }}
        >
          {t("courseCertificate.certificateOfCompletion")}
        </Typography>

        {/* Certificate Holder */}
        <Typography variant="h3" className="subtitle">
          {t("courseCertificate.certifyThat")}
        </Typography>
        <Typography variant="h1" className="name user-name">
          {name}
        </Typography>

        {/* Course Completion Information */}
        <Typography variant="h3" className="subtitle">
          {t("courseCertificate.hasCompleted")}
        </Typography>
        <Typography variant="h2" className="course">
          {course.courseName}
        </Typography>
        {course.courseLength && (
          <Typography
            variant="h6"
            className="course-length"
            style={{ fontSize: "18px" }}
          >
            {t("courseCertificate.lengthOfTraining")} {course.courseLength}
          </Typography>
        )}

        {!course?.courseName?.toLowerCase().includes("dexcom") && (
          <Typography
            variant="h4"
            className="instructor"
            style={{ fontSize: "18px" }}
          >
            {/* Instructor Information */}
            {t("courseCertificate.presenter")} <b>{course.instructorName}</b>{" "}
            <br />
            {instructorData[course.instructorName]?.designation
              ? ` ${instructorData[course.instructorName]?.designation}`
              : ""}
          </Typography>
        )}

        {/* Completion Date */}
        <Typography
          variant="h4"
          className="date"
          style={{
            textTransform: "uppercase",
            fontWeight: "bold",
            color: "#ababab",
          }}
        >
          {t("courseCertificate.dated")} {completedDateStr}
        </Typography>

        {/* MedEssist Logo and Link */}
        <Box className="medessist-container">
          <img src={logo} alt="MedEssist Logo" className="medessist-logo" />
          <Typography
            variant="h6"
            className="medessist"
            style={{ fontSize: "16px" }}
          >
            {t("courseCertificate.poweredBy")}{" "}
            <a
              href="http://www.hub.medessist.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              www.hub.medessist.com
            </a>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default CourseCertificate;
