import React from "react";
import { Hidden } from "@mui/material";
import PodcastsLinks from "../podcasts/PodcastsLinks";
import { useAuth } from "../../contexts/AuthContext";
import AIButton from "../../ai/AIButton";
import { accountsTrialingAI } from "../../ai/accountsTrialingAI";

export default function Footer({ handleOpenAIButton, openAI }) {
  const { currentUser } = useAuth();

  const stickerFooterStyle = {
    position: "fixed",
    bottom: "40px",
    right: "20px",
    textAlign: "center",
    display: !currentUser && "none",
  };

  return (
    <>
      {/* <div style={{ display: !currentUser && "none" }}>
        {(currentUser?.email?.includes("@medessist.com") ||
          accountsTrialingAI.includes(currentUser?.uid)) && (
          <AIButton handleOpenAIButton={handleOpenAIButton} openAI={openAI} />
        )}
      </div>
			 */}
      <Hidden smDown>
        <div style={stickerFooterStyle}>
          <PodcastsLinks />
        </div>
      </Hidden>
    </>
  );
}
