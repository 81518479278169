const learningResources = {
  // "How to Fish": [
  //   {
  //     type: "blog",
  //     title: "The Ultimate Guide to Fishing",
  //     link: "https://www.blog.com/fishing_guide",
  //   },
  //   {
  //     type: "poster",
  //     title: "Fishing Techniques Poster",
  //     link: "https://www.resources.com/fishing_poster.pdf",
  //   },
  // ],
  // "Pharmacy Course": [
  //   {
  //     type: "blog",
  //     title: "Understanding Pharmacy: A Comprehensive Guide",
  //     link: "https://www.blog.com/pharmacy_guide",
  //   },
  //   {
  //     type: "ebook",
  //     title: "Pharmacy Basics",
  //     link: "https://www.resources.com/pharmacy_basics.pdf",
  //   },
  // ],
  "Deprescribing 101": [
    {
      type: "website",
      title: "BEERS List",
      link: "https://www.ismp-canada.org/beers_list/#l=tab2",
    },
    {
      type: "website",
      title: "Anticholinergic Burden",
      link: "https://www.acbcalc.com/",
    },
    {
      type: "journal",
      title: "Drug Burden Index",
      link: "https://pubmed.ncbi.nlm.nih.gov/27909739/",
    },
    {
      type: "journal",
      title: "STOPP/START",
      link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4339726/",
    },
    {
      type: "website",
      title: "Choosing Wisely",
      link: "https://choosingwiselycanada.org/",
    },
    {
      type: "website",
      title: "Deprescribing.org",
      link: "https://deprescribing.org/",
    },
    {
      type: "website",
      title: "Taper MD",
      link: "https://tapermd.com/tapering-resources/",
    },
    {
      type: "website",
      title: "Medstopper.com",
      link: "https://medstopper.com/",
    },
    {
      type: "journal",
      title: "Deprescribing Patient Handout - JAMA Internal Med",
      link: "https://jamanetwork.com/journals/jamainternalmedicine/fullarticle/2805583",
    },
    {
      type: "website",
      title: "NSW Therapeutic Advisory Group Inc.",
      link: "https://nswtag.org.au/deprescribing-tools",
    },
    {
      type: "website",
      title: "Primary Health Tasmania",
      link: "https://www.primaryhealthtas.com.au/resources/deprescribing-resources/",
    },
  ],
  "Basal Insulin": [
    {
      icon: "poster",
      title: "Insulin Icodec - For Patients",
      link: "https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/resources%2FInsulin%20Icodec%20Handout%20for%20Patients.pdf?alt=media&token=60fd1dfe-76e1-4158-af7b-8da6cf5d1334",
      typeOfResource: "patient",
      group: "Patient Resources",
    },
    {
      icon: "poster",
      title: "Insulin Icodec - For Healthcare Providers",
      link: "https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/resources%2FInsulin%20Icodec%20Handout%20for%20HCPs.pdf?alt=media&token=26a640e4-036a-40f9-b103-c871ac8b51cb",
      group: "Provider Resources",
      typeOfResource: "provider",
    },
  ],
  Dexcom: [
    {
      icon: "poster",
      title:
        "Dexcom G7 Patient Benefit Program Copay Certificate (Rest of Canada)",
      link: "https://s3.us-west-2.amazonaws.com/dexcommisc/Canada+HCP/MAT-0568+CA+G7+Patient+Benefit+Program+Certificate+v3+standard.pdf",
      group: "Continuous Glucose Monitoring",
      typeOfResource: "provider",
    },
    {
      icon: "poster",
      title: "Dexcom G7 Patient Benefit Program Copay Certificate (Quebec)",
      link: "https://s3.us-west-2.amazonaws.com/dexcommisc/Canada+HCP/MAT-0568+CA+G7+Patient+Benefit+Program+Certificate+v3+aqpp.pdf",
      group: "Continuous Glucose Monitoring",
      typeOfResource: "provider",
    },
  ],
};

export default learningResources;
