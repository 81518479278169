import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  CircularProgress,
  Avatar,
  Box,
  Grid,
  Link,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LockIcon from "@mui/icons-material/Lock";
import { markCourseAsViewed } from "../../helpers/source";
import useCourseEnrollment from "../useCourseEnrollment";
import LicenseModal from "./LicenseModal";
import { updateUserLicenseNumber } from "../../helpers/source";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import PodcastsLinks from "../../podcasts/PodcastsLinks";
import { useLocation } from "react-router-dom";

function CourseTitlePage({
  hardCodedCourseContent,
  setIsCourseIntro,
  courseData,
  currentUser,
}) {
  const { t } = useTranslation();
  const [enrollmentLoading, setEnrollmentLoading] = useState(false);
  const [modalLicenseOpen, setLicenseModalOpen] = useState(false);
  const [displayAudio, setDisplayAudio] = useState(false);

  //this is for tracking where the user landed on this course from (eg google ads, fb etc)
  // eg: if URL is "https://hub.medessist.com/courses/basal_insulin_mastery_t2d?source=google",
  // the value of the source parameter will be "google"
  const location = useLocation();

  // Correct the search string to handle multiple question marks
  const correctedSearch = location.search.replace(/\?([^?]*)\?/, "?$1&");
  const queryParams = new URLSearchParams(correctedSearch);
  const source = queryParams.get("source");
  const numberOfModules = hardCodedCourseContent?.modules?.length || 0;
  const numberOfInstructors = hardCodedCourseContent?.instructors?.length || 0;

  useEffect(() => {
    if (currentUser && courseData?.courseId) {
      // Mark course as viewed when component rendered and user is logged in
      markCourseAsViewed(currentUser.uid, courseData.courseId);
      if (!currentUser.licenseNumber) {
        // Open modal if license number is missing
        setLicenseModalOpen(true);
      }
    }
  }, [currentUser, courseData]);

  const handleModalClose = () => {
    setLicenseModalOpen(false);
  };

  const handleLicenseSave = async (licenseNumber) => {
    if (currentUser && licenseNumber) {
      // Use the existing function to update the license number
      await updateUserLicenseNumber({ userId: currentUser.uid, licenseNumber });
      setLicenseModalOpen(false);
    }
  };

  // Use custom hook for managing course enrollment state
  const { enrolled, handleCourseEnrollment, handleSignInToEnroll } =
    useCourseEnrollment(courseData);

  // Handles the enrollment process
  const enroll = async () => {
    if (!currentUser) {
      handleSignInToEnroll(source);
      return;
    }
    setEnrollmentLoading(true);
    await handleCourseEnrollment(source);
    setEnrollmentLoading(false);
    setIsCourseIntro(false);
  };

  const checkLocation = () => {
    if (
      window.location.href.indexOf("/courses/managing-t2d-using-glp1ra") > -1 ||
      window.location.href.indexOf("/courses/basal_insulin_mastery_t2d") > -1
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    checkLocation();
  }, []);

  return (
    <>
      {checkLocation() && (
        <>
          <Helmet>
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=AW-11021658284"
            ></script>
            <script>
              {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'AW-11021658284');`}
            </script>
            <script>
              {`
      function gtag_report_conversion(url) {
        var callback = function () {
          if (typeof(url) != 'undefined') {
            window.location = url;
          }
        };
        gtag('event', 'conversion', {
            'send_to': 'AW-11021658284/fT9_CPqDya8ZEKzRxIcp',
            'event_callback': callback
        });
        return false;
      }  
    `}
            </script>
            <script>
              {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-P4BW2SS');`}
            </script>
          </Helmet>
          <noscript>
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=GTM-XXXX"
              height="0"
              width="0"
              style={{ display: "none", visibility: "hidden" }}
            ></iframe>
          </noscript>
        </>
      )}
      <Card
        variant="outlined"
        sx={{
          maxWidth: 900,
          margin: "auto",
          mt: 5,
          p: 3,
          borderRadius: 10,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CardContent style={{ width: "90%" }}>
          <Typography
            variant="h5"
            component="div"
            gutterBottom
            sx={{ textAlign: "left", fontWeight: "bold", mb: 2 }}
          >
            {hardCodedCourseContent?.courseTitle}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {hardCodedCourseContent?.courseDescription}
          </Typography>

          {/* Conditionally render Watch Time here if there's only one module */}
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", fontStyle: "italic", mt: 2 }}
          >
            {t("interactiveCourse.watchTime")}{" "}
            {hardCodedCourseContent.modules[0].watchTime}
          </Typography>
          <Divider variant="middle" sx={{ my: 2 }} />

          {/* LMC COLLAB STATEMENT */}
          {hardCodedCourseContent?.courseAccreditation && (
            <>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/educationHub%2FCCCEP%2FLMC_logo-highres-171-x-172.png?alt=media&token=7a658347-761c-4be7-8be5-2b8d1490883d"
                  style={{ marginRight: "5px", height: "4rem" }}
                  loading="lazy"
                  alt="LMC logo"
                />
                <div>
                  <Typography variant="body2" component="p" gutterBottom>
                    {hardCodedCourseContent.collaborationStatement}
                  </Typography>
                </div>
              </Box>
              {/* Conditionally render Divider only if Course Agenda is present */}
              {numberOfModules > 1 && (
                <Divider variant="middle" sx={{ my: 2 }} />
              )}
            </>
          )}

          {/* Display course accreditation details based on course */}
          {/* For French OPQ courses */}
          {hardCodedCourseContent?.courseAccreditation &&
            (courseData.courseId.includes("-fr") ? (
              <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
                <Typography variant="body2" component="p" gutterBottom>
                  Cette activité de formation continue est accréditée par
                  l’Ordre des pharmaciens du Québec.
                </Typography>
                {hardCodedCourseContent.courseAccreditation
                  .accreditationNumber && (
                  <Typography variant="body2" component="p" gutterBottom>
                    No d'accréditation :
                    {
                      hardCodedCourseContent.courseAccreditation
                        .accreditationNumber
                    }
                  </Typography>
                )}
                <Typography variant="body2" component="p">
                  {hardCodedCourseContent.courseAccreditation.ceus && (
                    <>
                      Nombre d’heures admissibles :{" "}
                      {hardCodedCourseContent.courseAccreditation.ceus}
                    </>
                  )}
                </Typography>
              </Box>
            ) : (
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/educationHub%2FCCCEP%2FCCCEP%20logo%20ACCREDITED%20colour.jpg?alt=media&token=bed29524-0990-4042-b5bb-1bf0bb03c489"
                  style={{ marginRight: "5px", height: "4rem" }}
                  loading="lazy"
                  alt="CCCEP logo"
                />
                <div>
                  {hardCodedCourseContent.courseAccreditation
                    .accreditationNumber && (
                    <Typography variant="body2" component="p" gutterBottom>
                      {
                        hardCodedCourseContent.courseAccreditation
                          .accreditationNumber
                      }
                    </Typography>
                  )}
                  <Typography variant="body2" component="p" gutterBottom>
                    {hardCodedCourseContent.courseAccreditation.ceus}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {
                      hardCodedCourseContent.courseAccreditation
                        .statementOfParticipation
                    }
                  </Typography>
                </div>
                {/* Conditionally render Divider only if Course Agenda is present */}
                {numberOfModules > 1 && (
                  <Divider variant="middle" sx={{ my: 1 }} />
                )}
              </Box>
            ))}

          {/* Conditionally render Course Agenda if there are more than one module */}
          {numberOfModules > 1 && (
            <>
              <Typography
                variant="h6"
                component="h2"
                gutterBottom
                sx={{ fontWeight: "bold", mt: 2 }}
              >
                {t("interactiveCourse.courseAgenda")}
              </Typography>

              {/* 2 by 2 table for agenda */}
              <Grid container spacing={3}>
                {hardCodedCourseContent.modules.map((module, index) => (
                  <Grid item xs={12} md={6} key={index}>
                    <Box sx={{ mt: 2 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "bold" }}
                      >
                        {module.title}
                      </Typography>
                      <Typography variant="body2">{module.subtitle}</Typography>
                      {module.watchTime && (
                        <Typography
                          variant="body2"
                          sx={{ fontStyle: "italic" }}
                        >
                          {t("interactiveCourse.watchTime")} {module.watchTime}
                        </Typography>
                      )}

                      <Typography variant="body2" sx={{ fontStyle: "italic" }}>
                        {module.ceu}
                      </Typography>
                      {module.accreditationNumber && (
                        <Typography
                          variant="body2"
                          sx={{ fontStyle: "italic" }}
                        >
                          {t("interactiveCourse.accreditationNumber")} :{" "}
                          {module.accreditationNumber}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                ))}
              </Grid>
              {/* Divider after Course Agenda */}
              <Divider variant="middle" sx={{ my: 2 }} />
            </>
          )}
          {hardCodedCourseContent?.learningObjectives && (
            <>
              <Typography
                variant="h6"
                component="h3"
                sx={{ fontWeight: "bold", mt: numberOfModules > 1 ? 0 : 2 }}
              >
                {t("interactiveCourse.learningObjectives")}
              </Typography>
              {/* List learning objectives dynamically */}
              <List>
                {hardCodedCourseContent?.learningObjectives.map(
                  (obj, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <CheckCircleIcon color="success" />
                      </ListItemIcon>
                      <ListItemText primary={obj} />
                    </ListItem>
                  )
                )}
              </List>
              <Divider variant="middle" sx={{ my: 2 }} />
            </>
          )}
          <Typography
            variant="h6"
            component="h3"
            sx={{ fontWeight: "bold", mt: 2 }}
          >
            {numberOfInstructors === 1
              ? t("interactiveCourse.instructor")
              : t("interactiveCourse.instructors")}
          </Typography>
          {/* Updated display for instructor information with LinkedIn links */}
          {hardCodedCourseContent.instructors.map((instructor, index) => (
            <Box
              key={index}
              sx={{ display: "flex", alignItems: "center", mb: 3 }}
            >
              <Avatar
                src={instructor.imageUrl}
                alt={instructor.name}
                sx={{ width: 75, height: 75, mr: 2 }}
                loading="lazy"
              />
              <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  {instructor.name}{" "}
                  {instructor.linkedinUrl && (
                    <Link
                      href={instructor.linkedinUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkedInIcon sx={{ verticalAlign: "middle", ml: 1 }} />
                    </Link>
                  )}
                </Typography>
                <Typography variant="body2">{instructor.bio}</Typography>
              </Box>
            </Box>
          ))}
          <Divider variant="middle" sx={{ my: 2 }} />
          <Typography variant="body2" component="p" gutterBottom>
            {hardCodedCourseContent?.sponsoredBy}
          </Typography>
          {/* Render enrollment button based on enrollment state */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              mt: 2,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* BUTTONS to enroll/continue or LISTEN to course */}
            {enrolled ? (
              <>
                <button
                  onClick={() => setIsCourseIntro(false)}
                  className="new-button-style primary teal-hover take-quiz-button"
                  style={{ padding: "0", margin: "0" }}
                >
                  {t("interactiveCourse.continueToCourse")}
                </button>
                {courseData?.audioLink && (
                  <>
                    <div className="audio-section" style={{ margin: "20px" }}>
                      <button
                        className="listen-audio-button"
                        onClick={() => setDisplayAudio(!displayAudio)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <HeadphonesIcon sx={{ marginRight: "8px" }} />{" "}
                        <span style={{ color: "gray" }}>
                          Listen to Audio Instead
                        </span>
                      </button>
                      {displayAudio && (
                        <>
                          <iframe
                            src={courseData?.audioLink}
                            title="Podcast"
                            className="audio-container"
                            style={{
                              width: "100%",
                              height: "150px",
                              border: "none",
                              marginTop: "1rem",
                            }}
                          />
                          <div style={{ marginTop: "1rem" }}>
                            <PodcastsLinks />
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </>
            ) : (
              <button
                className="new-button-style primary teal-hover take-quiz-button"
                onClick={() => {
                  if (checkLocation() && currentUser) {
                    window.gtag_report_conversion();
                  }
                  enroll();
                }}
                disabled={enrollmentLoading}
                style={{ padding: "10px 20px" }}
              >
                {enrollmentLoading ? (
                  <CircularProgress size={24} />
                ) : currentUser ? (
                  <>
                    <LockIcon sx={{ mr: 1 }} />
                    {t("interactiveCourse.enrollNow")}
                  </>
                ) : (
                  <>
                    <LockIcon sx={{ mr: 1 }} />
                    {t("interactiveCourse.signInToEnroll")}
                  </>
                )}
              </button>
            )}
          </Box>
        </CardContent>
      </Card>
      <LicenseModal
        open={modalLicenseOpen}
        handleClose={handleModalClose}
        handleSave={handleLicenseSave}
      />
    </>
  );
}

export default CourseTitlePage;
