/* global pipwerks */
import React, { useEffect, useState, useRef } from "react";
import Rating from "@mui/material/Rating";
import { getScormData, rateCourse } from "../helpers/source";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

function ScormCoursePage({ courseData, currentUser, userObject }) {
  const courseId = courseData?.courseId;
  const coursePath = courseData?.scormUrlPath;
  const commitIntervalRef = useRef(null);

  // Check if course is French
  const isFrenchCourse = courseId === "dexcom_fr";

  // Modal for "course passed => do survey"
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [hasCompleted, setHasCompleted] = useState(false);

  // --- RATING STATES (5 star system) ---
  const [rating, setRating] = useState(0);
  const [submittedRating, setSubmittedRating] = useState(false);
  const [thankYouMessage, setThankYouMessage] = useState("");

  const { courseName } = courseData || {};

  // Whenever hasCompleted becomes true, show the survey modal (happens after they pass quiz)
  useEffect(() => {
    if (hasCompleted) {
      setShowSurveyModal(true);
    }
  }, [hasCompleted]);

  // SCORM can call this when user passes the quiz
  useEffect(() => {
    window.setReactHasCompleted = (value) => {
      setHasCompleted(value);
    };

    // LMS finish will call this to redirect user to their profile (from scormApi.js)
    window.redirectToProfile = () => {
      window.location.href = "/profile";
    };

    return () => {
      delete window.setReactHasCompleted;
      delete window.redirectToProfile;
    };
  }, []);

  useEffect(() => {
    // 1) Initialize SCORM
    const success = window.pipwerks.SCORM.init();
    if (success) {
      console.log("SCORM connection established!");
    } else {
      console.error("SCORM connection failed!");
    }

    // 2) Load existing SCORM data from backend
    if (currentUser && courseId) {
      getScormData(currentUser.uid, courseId).then((existingData) => {
        if (!window.API.scormCache) {
          window.API.scormCache = {};
        }
        window.API.scormCache = existingData || {};

        // Initialize the interactionCounter if present
        if (existingData?.interactionCounter) {
          window.API.interactionCounter = existingData.interactionCounter;
        }
      });
    }

    // 3) Set up auto-commit every 2 min
    commitIntervalRef.current = setInterval(() => {
      window.API.LMSCommit();
    }, 2 * 60 * 1000);

    // 4) Commit on unload
    const handleBeforeUnload = () => {
      window.API.LMSCommit();
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup on unmount
    return () => {
      window.API.LMSCommit();
      clearInterval(commitIntervalRef.current);
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.pipwerks.SCORM.quit();
    };
  }, [currentUser, courseId]);

  // Warn user if they try to leave without completing
  useEffect(() => {
    function handleBeforeUnload(e) {
      if (!hasCompleted) {
        e.preventDefault();
        e.returnValue = "";
      }
    }
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasCompleted]);

  // survey link based on language
  const googleSurveyLink = isFrenchCourse
    ? //french survey
      "https://docs.google.com/forms/d/e/1FAIpQLSep8bBCPVfXvDmhDyQwTZJQG8FhKTfQCYvtlb2bMzv_RSDHhQ/viewform"
    : // english survey
      "https://docs.google.com/forms/d/1LbbbR5Kcx4QF7DM5iIwigN8EMXbJd6fhDuFr3C5mQbU/viewform?usp=sf_link";

  // Handle rating changes
  const handleChangeRating = async (event, newValue) => {
    setRating(newValue);

    if (newValue > 0 && currentUser) {
      // Save rating to DB
      await rateCourse({
        userId: currentUser.uid,
        courseId,
        rating: newValue,
      });

      // Update message
      if (submittedRating) {
        setThankYouMessage(
          `Your rating for ${courseName} has been updated. Thank you!`
        );
      } else {
        setThankYouMessage(`Thanks for rating ${courseName}!`);
      }
      setSubmittedRating(true);
    }
  };

  // Modal "Take Survey" => open in new tab
  const handleTakeSurvey = () => {
    window.open(googleSurveyLink, "_blank");
    setShowSurveyModal(false);
  };

  const handleNoThanks = () => {
    setShowSurveyModal(false);
  };

  // SCORM content URL
  //const baseURL = "http://localhost:3000";
  //const baseURL = "https://test-edu-hub.web.app";
  const baseURL = "https://hub.medessist.com/";
  const scormUrl = `${baseURL}/${coursePath}`;

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <iframe
        src={scormUrl}
        width="100%"
        height="100%"
        title="SCORM Course"
        frameBorder="0"
        allowFullScreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture sync-script"
      />
      {/* MUI Dialog for "passed => do survey?" */}
      <Dialog open={showSurveyModal} onClose={handleNoThanks}>
        <DialogTitle>
          <Typography variant="h6">
            {isFrenchCourse ? "Cours terminé" : "Course Completed"}
          </Typography>{" "}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {isFrenchCourse
              ? "Merci d'avoir terminé le cours ! Avant de partir, veuillez prendre un moment pour partager vos impressions dans un court sondage."
              : "Thank you for completing the course! Before you go, please take a moment to share your feedback in a quick survey."}
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            {isFrenchCourse
              ? "Votre certificat est maintenant disponible dans votre profil."
              : "Your certificate is now available in your profile."}
          </Typography>

          {/* Centered Rating */}
          <div style={{ textAlign: "center", marginTop: "1rem" }}>
            Rate the Course
            <br />
            <Rating
              name="course-rating"
              value={rating}
              onChange={handleChangeRating}
              size="large"
            />
            {thankYouMessage && (
              <Typography variant="body2" sx={{ mt: 0.5 }}>
                {thankYouMessage}
              </Typography>
            )}
          </div>
        </DialogContent>

        <DialogActions style={{ justifyContent: "center" }}>
          <button
            className="new-button-style secondary"
            onClick={handleNoThanks}
          >
            {isFrenchCourse ? "Non, merci" : "No Thanks"}
          </button>
          <button
            className="new-button-style primary"
            onClick={handleTakeSurvey}
          >
            {isFrenchCourse ? "Faire le sondage" : "Take Survey"}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ScormCoursePage;
