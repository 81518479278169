const rybelsusCourseEn = {
  numberOfSections: 1,
  courseId: "rybelsus-revealed-practical-oral-glp1ra",
  courseTitle: "Rybelsus Revealed: A Practical Approach to Oral GLP-1 RAs",
  courseDescription:
    "This module explores Rybelsus, the first oral GLP-1 receptor agonist, and its impact on the management of Type 2 Diabetes. Building on the Managing Type 2 Diabetes with GLP-1 RAs series, this course offers an in-depth look at oral semaglutide and its clinical application for community practitioners.",
  // this course is not accredited
  /*   courseAccreditation: {
    accreditationNumber: "",
    ceus: "",
    statementOfParticipation: "",
  }, */
  /*   collaborationStatement: "", */
  learningObjectives: [
    "Describe the indications, contraindications, side effects, interactions, dosage, and other important counselling points of Rybelsus",
    "Identify the barriers to GI absorption for protein-based drugs and describe how Rybelsus overcomes these barriers",
    "Describe the effect of Rybelsus on A1C, weight loss, CKD, MACE",
    "Describe other pleiotropic effects of Rybelsus – Alzheimer’s Disease, NAFLD/NASH",
    "Describe the benefits of Rybelsus",
    "Describe who might be an ideal candidate",
    "Understand the results of key trials– PIONEER (1, 2, 3, 5, 6, 10); SOUL",
  ],
  instructors: [
    {
      name: "Lysa Leong",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/education-hub-848d2.appspot.com/o/educationHub%2Fheadshots%2Fimage%20Lysa%20Leong.png?alt=media&token=88d05ac8-0928-4c6c-b0e4-7a4000415810",
      bio: "Lysa Leong graduated from UBC Pharmacy in 2012. She has been a Certified Diabetes Educator since 2014 and a Clinical Instructor with UBC since 2018. Currently, she is also a Pharmacist at BioPro Biologics Pharmacy. Her areas of practice are largely focused on diabetes, dyslipidemia, rheumatic diseases, and sleep medicine. In her spare time, she enjoys sharing her knowledge through various social media platforms (@TheCheerfulPharmacist) like TikTok, Spotify and Apple Podcasts, Instagram, LinkedIn, and Substack.",
      linkedinUrl: "https://www.linkedin.com/in/lysaleong",
    },
  ],

  modules: [
    {
      title: "Rybelsus Mini Course",
      subtitle:
        "Focusing on Rybelsus, the innovative oral GLP-1 RA (oral semaglutide)",
      watchTime: "29 minutes",
    },
  ],
  surveyLink:
    "https://docs.google.com/forms/d/1a5xZBXY9X-U_YdUHURrqCmtZwbhOHnTm7_eu-FRxPuw",
  sponsoredBy:
    "The course has been supported by an unrestricted educational grant from Novo Nordisk Canada.",
  sections: [
    /* SECTION 1 */
    {
      id: "module-1",
      /*       title: "Rybelsus Revealed", */
      /*   description:
        "An in-depth exploration of Rybelsus, the first oral GLP-1 receptor agonist, and its role in managing Type 2 Diabetes.", */
      instructor: "Lysa Leong, RPh, CDE",
      agenda: [
        "What is Rybelsus?",
        "From Injection to Ingestion",
        "Benefits, Considerations, and Pleiotropic Effects",
        "Counselling Pearls",
      ],
      watchTime: "29 minutes",
      steps: [
        /* VIDEO PART 1 */
        {
          type: "video",
          src: "https://vimeo.com/1049818587/ccb2465e86",
          videoId: "mod_1-vid_1",
          title: "Rybelsus Part 1: Introduction and Indications",
        },

        /* QUIZ 1 */
        {
          type: "quiz",
          quizId: "mod_1-quiz-1",
          questions: [
            {
              question:
                "Semaglutide (oral or sc) can be used in combination with the following except:",
              options: [
                "Metformin",
                "Sulfonylureas",
                "DPP4 inhibitors",
                "Insulin",
              ],
              answer: "DPP4 inhibitors",
            },
          ],
          title: "Combination Therapy with Semaglutide Quiz",
        },

        /* VIDEO PART 2 */
        {
          type: "video",
          src: "https://vimeo.com/1049819809/621db0010b",
          videoId: "mod_1-vid_2",
          title: "Rybelsus Part 2: Administration Guidelines",
        },

        /* QUIZ 2 */
        {
          type: "quiz",
          quizId: "mod_1-quiz-2",
          questions: [
            {
              question: "How should Rybelsus be administered?",
              options: [
                "On an empty stomach",
                "With a full glass of water (250 ml)",
                "With as much water as you’d like",
                "With food",
                "None of the above",
                "Can be any of the above",
              ],
              answer: "On an empty stomach",
            },
          ],
          title: "Rybelsus Administration Quiz",
        },

        /* VIDEO PART 3 */
        {
          type: "video",
          src: "https://vimeo.com/1049821503/09c2231432",
          videoId: "mod_1-vid_3",
          title: "Rybelsus Part 3: Assessing Appropriateness",
        },

        /* QUIZ 3 */
        {
          type: "quiz",
          quizId: "mod_1-quiz-3",
          questions: [
            {
              question: "Who might benefit from Rybelsus?",
              options: [
                "a) SS is 42yo male inadequately managed on maximally-tolerated metformin. His A1C is 8.2%. He travels a lot.",
                "b) RR is a 65yo female on metformin, empagliflozin, and mostly recently Ozempic but having a lot of difficulty with injections.",
                "c) TT is a 59yo male who is inadequately managed on maximally-tolerated metformin and glyburide. You find out that his plan doesn’t cover po semaglutide.",
                "d) YY is a 39yo female with T2DM and a BMI of 28. She is inadequately managed on metformin, and insulin basaglar.",
                "a and b",
                "a, b and d",
              ],
              answer: "a, b and d",
            },
          ],
          title: "Assessing Appropriateness for Rybelsus Quiz",
        },

        /* VIDEO PART 4 */
        {
          type: "video",
          src: "https://vimeo.com/1049823098/31db4e2bf7",
          videoId: "mod_1-vid_4",
          title: "Rybelsus Part 4: Interactions and Recommendations",
        },

        /* QUIZ 4 */
        {
          type: "quiz",
          quizId: "mod_1-quiz-4",
          questions: [
            {
              question:
                "RD is a 65yo female who takes alendronate 70mg once weekly in the morning, metformin 500mg ii bid and dapagliflozin 10mg od. She has brought in a prescription for Rybelsus 1.5mg od x 30 days and then 4mg od for 30 days. What would be an appropriate recommendation?",
              options: [
                "a) Tell RD to take Rybelsus with alendronate 70mg in the AM on an empty stomach",
                "b) Tell RD to take Rybelsus in the AM on an empty stomach, then wait 30 minutes before taking Alendronate 70mg and then wait another 30 minutes before first food",
                "c) Suggest contacting the physician to discuss switching alendronate 70mg weekly to risedronate DR 35mg weekly",
                "d) Suggest contacting the physician to discuss switching alendronate 70mg weekly to zoledronic acid 5mg IV once yearly",
                "c and d",
                "b, c and d",
              ],
              answer: "c and d",
            },
          ],
          title: "Rybelsus Interactions Quiz",
        },

        /* VIDEO PART 5 */
        {
          type: "video",
          src: "https://vimeo.com/1049824096/2b2e3405ef",
          videoId: "mod_1-vid_5",
          title: "Rybelsus Part 5: Clinical Trials and Outcomes",
        },

        //DONE
        {
          type: "section-done",
          sectionIndex: 1,
          sectionId: "module-1",
        },
      ],
    },
  ],
};

export default rybelsusCourseEn;
