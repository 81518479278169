import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import VimeoPlayer from "./interactive-course-data/VimeoPlayer";
import StepQuiz from "./interactive-course-data/StepQuiz";
import StepQuizCheckboxes from "./interactive-course-data/StepQuizCheckboxes";
import StepQuizGraded from "./interactive-course-data/StepQuizGraded";
import LongAnswerQuestion from "./interactive-course-data/LongAnswerQuestion";
import ProgressPanel from "./interactive-course-data/ProgressPanel";
import CourseTitlePage from "./interactive-course-data/CourseTitlePage";
import FreeAnswerMCQ from "./interactive-course-data/FreeAnswerMCQ";
import {
  updateInteractiveCourseProgress,
  updateVideoProgress,
  addModuleToStarted,
  saveAnswersToDb,
} from "../helpers/source";
import { useUser } from "../../contexts/UserProvider";
import SectionDone from "./interactive-course-data/SectionDone";
import { LinearProgress } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import "./interactive-course.scss";
import glpCourseEng from "./interactive-course-data/hardCodedCourse/interactiveCourseData";
import glpCourseFr from "./interactive-course-data/hardCodedCourse/interactiveCourseData_fr";
import basalInsulinMasterySeries from "./interactive-course-data/hardCodedCourse/basal-insulin";
import basalInsulinMasterySeriesFr from "./interactive-course-data/hardCodedCourse/basal-insulin-fr";
import rybelsusCourse from "./interactive-course-data/hardCodedCourse/rybelsus-en";
import SectionDoneMultiCEUCourse from "./interactive-course-data/SectionDoneMultiCEUCourse";
import Forum from "./interactive-course-data/Forum";
import { useTranslation } from "react-i18next";

function InteractiveCoursePage({ currentUser, courseData }) {
  const { userObject } = useUser();
  const { courseId } = useParams();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [hardCodedCourseContent, setHardCodedCourseContent] = useState(null);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [isSectionIntro, setIsSectionIntro] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [isCourseIntro, setIsCourseIntro] = useState(true);
  const [passedQuizzes, setPassedQuizzes] = useState({});
  const [watchedVideos, setWatchedVideos] = useState({});
  const [completedSections, setCompletedSections] = useState({});
  const [canAccessEvaluation, setCanAccessEvaluation] = useState(false);
  const [scorePercentage, setScorePercentage] = useState(0);

  const [currentTimestamp, setCurrentTimestamp] = useState(0);
  const [videoId, setVideoId] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    //to get module and step from URL params
    //The URL should look like this: /courses/the-course-id?module=1&step=2
    const moduleIndex = searchParams.get("module");
    const stepIndex = searchParams.get("step");

    let holdHardCodedCourseData;
    if (courseId === "managing-t2d-using-glp1ra") {
      holdHardCodedCourseData = glpCourseEng;
      i18n.changeLanguage("en");
    } else if (courseId === "managing-t2d-using-glp1ra-fr") {
      holdHardCodedCourseData = glpCourseFr;
      i18n.changeLanguage("fr");
    } else if (courseId === "basal_insulin_mastery_t2d") {
      holdHardCodedCourseData = basalInsulinMasterySeries;
      i18n.changeLanguage("en");
    } else if (courseId === "basal_insulin_mastery_t2d-fr") {
      holdHardCodedCourseData = basalInsulinMasterySeriesFr;
      i18n.changeLanguage("fr");
    } else if (courseId === "rybelsus") {
      holdHardCodedCourseData = rybelsusCourse;
      i18n.changeLanguage("en");
    } else {
      holdHardCodedCourseData = null;
    }

    if (holdHardCodedCourseData) {
      // Record of the quizzes already passed by user
      const quizResults = userObject?.courses?.[courseId]?.quizResults || {};

      // Record of vids watched already passed by user
      const videosWatchedRecords =
        userObject?.courses?.[courseId]?.videoProgress || {};

      const evaluationQuizScore =
        userObject?.courses?.[courseId]?.quizResults?.evaluationQuizScore || "";
      const completedSectionsRecords =
        userObject?.courses?.[courseId]?.completedSections || {};

      setScorePercentage(evaluationQuizScore);

      const displayTitlePage =
        !currentUser || // Show if user is not logged in
        (currentUser &&
          userObject?.courses?.[courseId]?.enrolled &&
          !moduleIndex) || // Show if user is logged in, enrolled in course, but no module is specified
        !userObject?.courses?.[courseId]?.enrolled; // Show if user is logged in but not enrolled in the course

      setIsCourseIntro(displayTitlePage); // Set to false if moduleIndex is present
      setHardCodedCourseContent(holdHardCodedCourseData);
      setIsSectionIntro(true);
      // Load quiz results from userObject and merge with local storage data
      setPassedQuizzes(quizResults);
      setWatchedVideos(videosWatchedRecords);
      setCompletedSections(completedSectionsRecords);

      // Check if all modules are completed (to unlock quiz)
      const allModulesCompleted = holdHardCodedCourseData.sections
        .filter((section) => section.id !== "evaluation")
        .every((section) => completedSectionsRecords[section.id]);

      setCanAccessEvaluation(allModulesCompleted);

      // Set the current section and step based on URL parameters
      if (moduleIndex) {
        const sectionIndex = parseInt(moduleIndex, 10) - 1; // adjusting for zero-based indexing
        if (
          !isNaN(sectionIndex) &&
          sectionIndex < holdHardCodedCourseData.sections.length
        ) {
          setCurrentSectionIndex(sectionIndex);
          setActiveStep(sectionIndex);
        }
      }

      if (stepIndex) {
        const stepIdx = parseInt(stepIndex, 10);
        if (
          !isNaN(stepIdx) &&
          stepIdx <
            holdHardCodedCourseData.sections[moduleIndex - 1].steps.length
        ) {
          setCurrentStepIndex(stepIdx);
        }
      }
    }
  }, [courseId, i18n, userObject?.courses, location.search]);

  useEffect(() => {
    setCurrentStepIndex(0);
    if (!hardCodedCourseContent) return;

    // Check if all modules are completed (to unlock quiz)
    const allModulesCompleted = hardCodedCourseContent.sections
      .filter((section) => section.id !== "evaluation")
      .every((section) => completedSections[section.id]);

    setCanAccessEvaluation(allModulesCompleted);
  }, [currentSectionIndex]);

  if (!hardCodedCourseContent) {
    return <div>Loading...</div>;
  }

  const currentSection = hardCodedCourseContent.sections[currentSectionIndex];
  const currentStepObject = currentSection.steps[currentStepIndex];

  // Update timestamp and videoId when player updates
  const handleTimeUpdate = (time) => {
    setCurrentTimestamp(time);

    setVideoId(currentStepObject.videoId);
  };

  const handleNextStep = () => {
    if (isSectionIntro) {
      setIsSectionIntro(false);
    } else {
      const nextStepIndex = currentStepIndex + 1;
      if (nextStepIndex < currentSection.steps.length) {
        setCurrentStepIndex(nextStepIndex);
      } else if (
        currentSectionIndex + 1 <
        hardCodedCourseContent.sections.length
      ) {
        setCurrentSectionIndex(currentSectionIndex + 1);
        setIsSectionIntro(true);
        setCurrentStepIndex(0);
        setActiveStep(currentSectionIndex + 1);
      }
    }
  };

  const handlePreviousStep = () => {
    // Check if we're at the beginning of a section (step index 0)
    if (currentStepIndex === 0) {
      if (isSectionIntro) {
        // if it's also the first section (so no section before it), return to the course title page
        if (currentSectionIndex === 0) {
          setIsCourseIntro(true);
        } else {
          // else, move to the previous section's intro slide
          setCurrentSectionIndex(currentSectionIndex - 1);
          setIsSectionIntro(true);
          // reset the step index to the last step of the previous section, in case of reverse navigation
          setCurrentStepIndex(
            hardCodedCourseContent.sections[currentSectionIndex - 1].steps
              .length - 1
          );
        }
      } else {
        // if not in an introduction, navigate back to the introduction of the current section
        setIsSectionIntro(true);
      }
    } else {
      // for other cases, simply move to the previous step
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };

  const handleVideoCompletion = async (videoId) => {
    // record that user watched video in db
    await updateVideoProgress({
      userId: currentUser?.uid,
      courseId,
      videoId,
    });

    const updatedWatchedVideos = { ...watchedVideos, [videoId]: true };
    setWatchedVideos(updatedWatchedVideos);
    handleNextStep();
  };

  const handleQuizPass = (quizId, score) => {
    const updatedQuizzes = {
      ...passedQuizzes,
      [quizId]: true,
      ...(score !== undefined ? { evaluationQuizScore: score } : {}),
    };

    setPassedQuizzes(updatedQuizzes);
    updateInteractiveCourseProgress({
      userId: currentUser?.uid,
      courseId,
      progress: [],
      quizResults: updatedQuizzes,
    });
    handleNextStep();
  };

  const updateProgress = ({ quizId, passed }) => {
    const updatedQuizzes = { ...passedQuizzes, [quizId]: passed };
    setPassedQuizzes(updatedQuizzes);
    updateInteractiveCourseProgress({
      userId: currentUser?.uid,
      courseId,
      quizResults: updatedQuizzes,
    });
  };

  //PROGESS bar to show the STEP that the user is on within the section
  const renderStepProgress = () => (
    <div className="centered-container">
      <LinearProgress
        variant="determinate"
        value={(currentStepIndex / (currentSection.steps.length - 1)) * 100}
        sx={{ margin: "20px 0", height: "10px", borderRadius: "5px" }}
      />
    </div>
  );

  // handle the course title click
  const handleCourseTitleClick = () => {
    setIsCourseIntro(true);
  };

  const handleStartModule = async () => {
    //source.js function to write in user's document which modules they started
    await addModuleToStarted({
      userId: currentUser?.uid,
      courseId,
      moduleId: currentSection.id,
    });
    setIsSectionIntro(false);
  };

  const renderStep = () => {
    //dont need intro section for quiz
    if (isSectionIntro && currentSection.id !== "evaluation") {
      return (
        <div
          className="centered-container"
          style={{
            textAlign: "center",
            maxWidth: "800px",
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          }}
        >
          <h2 style={{ fontSize: "24px", color: "#333" }}>
            {currentSection?.title}
          </h2>
          <h3
            style={{ fontSize: "18px", color: "#555", margin: "10px 0 20px" }}
          >
            {currentSection?.description}
          </h3>
          <p
            style={{
              fontSize: "16px",
              color: "#666",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AccessTimeIcon style={{ marginRight: "8px" }} />
            {t("interactiveCourse.watchTime")} {currentSection?.watchTime}
          </p>
          <p
            style={{
              marginTop: "5px",
              fontSize: "18px",
              fontWeight: "bold",
              color: "#444",
            }}
          >
            {t("interactiveCourse.presentedBy")} {currentSection?.instructor}
          </p>
          {currentSection?.agenda && (
            <div
              style={{
                textAlign: "left",
                marginTop: "20px",
                padding: "10px 40px",
              }}
            >
              <h4
                style={{
                  fontSize: "18px",
                  color: "#444",
                  marginBottom: "10px",
                }}
              >
                {t("interactiveCourse.agenda")}:
              </h4>
              <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
                {currentSection?.agenda?.map((item, index) => (
                  <li
                    key={index}
                    style={{
                      marginBottom: "8px",
                      fontSize: "16px",
                      color: "#666",
                    }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {/* Learning Objectives for module */}
          {currentSection?.learningObjectives && (
            <div
              style={{
                textAlign: "left",
                marginTop: "20px",
                padding: "10px 40px",
              }}
            >
              <h4
                style={{
                  fontSize: "18px",
                  color: "#444",
                  marginBottom: "10px",
                }}
              >
                {t("interactiveCourse.learningObjectives")}:
              </h4>
              {/*  map out learningObjectives bullet points */}
              <ul style={{ listStyleType: "circle", paddingLeft: "20px" }}>
                {currentSection?.learningObjectives?.map((item, index) => (
                  <li
                    key={index}
                    style={{
                      marginBottom: "8px",
                      fontSize: "16px",
                      color: "#666",
                    }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          )}
          <button
            onClick={handlePreviousStep}
            className="new-button-style secondary"
            style={{ marginRight: "10px" }}
          >
            {t("interactiveCourse.back")}
          </button>

          <button
            onClick={handleStartModule} // call the new function here
            className="new-button-style primary"
          >
            {t("interactiveCourse.startModule")}
          </button>
        </div>
      );
    } else if (currentStepObject?.type === "video") {
      return (
        <VimeoPlayer
          handleNextStep={handleNextStep}
          currentStepObject={currentStepObject}
          src={currentStepObject.src}
          handleVideoCompletion={handleVideoCompletion}
          handlePreviousStep={handlePreviousStep}
          currentStepIndex={currentStepIndex}
          isVideoCompleted={watchedVideos?.[currentStepObject.videoId] || false}
          onTimeUpdate={handleTimeUpdate}
        />
      );
    } else if (currentStepObject?.type === "quiz") {
      return (
        <StepQuiz
          quizData={currentStepObject}
          onQuizPass={() => handleQuizPass(currentStepObject.quizId)}
          isPassed={passedQuizzes?.[currentStepObject.quizId] || false}
          updateProgress={updateProgress}
          handlePreviousStep={handlePreviousStep}
          currentStepIndex={currentStepIndex}
        />
      );
    } else if (currentStepObject?.type === "free-answer-mcq") {
      return (
        <FreeAnswerMCQ
          quizData={currentStepObject}
          onQuizPass={() => handleQuizPass(currentStepObject.quizId)}
          isPassed={passedQuizzes?.[currentStepObject.quizId] || false}
          updateProgress={updateProgress}
          handlePreviousStep={handlePreviousStep}
          currentStepIndex={currentStepIndex}
        />
      );
    } else if (currentStepObject.type === "quiz-checkboxes") {
      return (
        <StepQuizCheckboxes
          quizData={currentStepObject}
          isPassed={passedQuizzes?.[currentStepObject.quizId] || false}
          updateProgress={updateProgress}
          handlePreviousStep={handlePreviousStep}
          onQuizPass={() => handleQuizPass(currentStepObject.quizId)}
          currentStepIndex={currentStepIndex}
        />
      );
    } else if (currentStepObject.type === "graded-quiz") {
      return (
        <StepQuizGraded
          quizData={currentStepObject}
          handleQuizPass={handleQuizPass}
          handleNextStep={handleNextStep}
          isPassed={passedQuizzes?.[currentStepObject.quizId] || false}
          updateProgress={updateProgress}
          handlePreviousStep={handlePreviousStep}
          currentStepIndex={currentStepIndex}
          canAccessEvaluation={canAccessEvaluation}
          setScorePercentage={setScorePercentage}
          scorePercentage={scorePercentage}
        />
      );
    } else if (currentStepObject.type === "section-done-multi-ceu") {
      // eg basal insulin course, where each module is its own course and gets its own ceu credit
      return (
        <SectionDoneMultiCEUCourse
          currentStepObject={currentStepObject}
          handlePreviousStep={handlePreviousStep}
          courseId={courseId}
          currentUser={currentUser}
          handleNextStep={handleNextStep}
          setCompletedSections={setCompletedSections}
          completedSections={completedSections}
          numberOfSections={hardCodedCourseContent?.numberOfSections}
          hardCodedCourseContent={hardCodedCourseContent}
        />
      );
    } else if (currentStepObject.type === "section-done") {
      return (
        <SectionDone
          currentStepObject={currentStepObject}
          handlePreviousStep={handlePreviousStep}
          courseId={courseId}
          currentUser={currentUser}
          handleNextStep={handleNextStep}
          setCompletedSections={setCompletedSections}
          completedSections={completedSections}
          numberOfSections={hardCodedCourseContent?.numberOfSections}
          hardCodedCourseContent={hardCodedCourseContent}
        />
      );
    } else if (currentStepObject?.type === "long-answer") {
      return (
        <LongAnswerQuestion
          questionData={currentStepObject}
          onAnswerSave={(questionId, answer) => {
            // Save the answer to the user's course progress
            saveAnswersToDb({
              questionId,
              answer,
              courseId,
              userId: currentUser?.uid,
            });
            handleNextStep();
          }}
          savedAnswer={
            userObject?.courses?.[courseId]?.answers?.[
              currentStepObject.questionId
            ]
          }
          handlePreviousStep={handlePreviousStep}
          currentStepIndex={currentStepIndex}
        />
      );
    }
    return null;
  };

  return (
    <div>
      {isCourseIntro ? (
        <CourseTitlePage
          courseData={courseData}
          hardCodedCourseContent={hardCodedCourseContent}
          setIsCourseIntro={setIsCourseIntro}
          currentUser={userObject}
        />
      ) : (
        <>
          <h1 onClick={handleCourseTitleClick} style={{ cursor: "pointer" }}>
            {hardCodedCourseContent.courseTitle}
          </h1>
          {/* back to course title page button */}
          <button
            onClick={handleCourseTitleClick}
            className="new-button-style secondary"
            style={{ margin: "10px" }}
          >
            Back to Course Page
          </button>
          {/* Progress bar to show which SECTION you are on */}
          <ProgressPanel
            sections={hardCodedCourseContent.sections}
            setCurrentSection={setCurrentSectionIndex}
            setIsSectionIntro={setIsSectionIntro}
            // courseId={courseId}
            activeStep={activeStep}
            completedSections={completedSections}
            currentUser={userObject}
            setActiveStep={setActiveStep}
          />
          {renderStepProgress()}
          {renderStep()}
          {/* FORUMS */}
          <Forum
            courseId={courseId}
            sections={hardCodedCourseContent.sections}
            currentModule={currentSectionIndex}
            vimeoTimestamp={currentTimestamp}
            videoId={videoId}
          />
        </>
      )}
      {/* add email of acc that you want access to bypass the course itself */}
      {["bingyujaeyoung@gmail.com", "peanut@butter.com"]?.includes(
        userObject?.email
      ) && <button onClick={() => handleNextStep()}>GO AHEAD</button>}
    </div>
  );
}

export default InteractiveCoursePage;
